import React, { useEffect, useRef } from 'react';

import { useMedia } from '../../context/MediaContext';
import { useSiniestro } from '../../context/SiniestroContext';
import { useFile } from '../../hooks/useDocumentacionFile';
import { PhotoItem } from '../../interfaces/PhotoItem';
import FormAddDocObligatoria from '../FormAddDocObligatoria';
import Gallery from '../Gallery';
import ToastAlert from '../ToastAlert';
import './DocumentacionObligatoria.scss';
import { DocumentacionObligatoriaProps } from './types';

const DocumentacionObligatoria: React.FC<DocumentacionObligatoriaProps> = ({
  nombre,
}) => {
  const { siniestroId } = useSiniestro();
  const {
    photos,
    clearPhotos,
    clearPDFs,
    showFileSizeValidationToast,
    setShowFileSizeValidationToast,
  } = useMedia();
  const { onFileChange } = useFile();

  // Ref para almacenar la versión anterior de photos
  const prevPhotosRef = useRef<PhotoItem[]>(photos);

  useEffect(() => {
    // Obtener la versión anterior de photos
    const prevPhotos = prevPhotosRef.current;

    // Mapear detalles a nombres de campos
    const tags: { [key: string]: PhotoItem | null } = {
      dni_frente: null,
      dni_dorso: null,
      libreta_vacunacion: null,
      cbu: null,
      tercero_cbu: null,
      constancia: null,
      doc_extra: null,
    };

    // Buscar los elementos necesarios
    photos.forEach((photo) => {
      if (tags.hasOwnProperty(photo.tag.detail)) {
        tags[photo.tag.detail] = photo;
      }
    });

    // Comparar con la versión anterior para encontrar cambios
    const changes = Object.keys(tags).reduce((acc, key) => {
      const currentTag = tags[key];
      const prevTag = prevPhotos.find((photo) => photo.tag.detail === key);

      if (
        currentTag &&
        (!prevTag || currentTag.webviewPath !== prevTag.webviewPath)
      ) {
        acc[key] = currentTag;
      }

      return acc;
    }, {} as { [key: string]: PhotoItem });

    // Ejecutar onFileChange solo para los campos que han cambiado
    Object.keys(changes).forEach((key) => {
      const tag = changes[key];
      if (tag) {
        onFileChange(siniestroId, tag.tag.detail, true, tag.webviewPath);
      }
    });

    // Actualizar la referencia con la versión actual de photos
    prevPhotosRef.current = photos;
  }, [photos, siniestroId, onFileChange]);

  return (
    <div className="documentacion-obligatoria">
      <div className="documentacion-obligatoria__heading ion-padding-horizontal ion-padding-bottom">
        <h1>Documentación obligatoria</h1>
        <p>
          Subí tu <span className="bold">DNI</span>, la
          <span className="bold"> libreta de vacunación</span> de {nombre} y la
          <span className="bold"> constancia de CBU</span> donde quieres recibir
          el reintegro.
        </p>
      </div>
      <FormAddDocObligatoria />
      <Gallery />

      <ToastAlert
        isOpen={showFileSizeValidationToast}
        setShowToast={setShowFileSizeValidationToast}
        message="Ups... el tamaño maximo admitido es 8 mb."
        color="warning"
        duration={4500}
      />
      {/* DEV */}
      {/* <button
        onClick={() => {
          clearPhotos();
          clearPDFs();
        }}
      >
        Clear media
      </button> */}
      {/* END DEV */}
    </div>
  );
};

export default DocumentacionObligatoria;
