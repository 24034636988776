import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';

import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { closeSharp } from 'ionicons/icons';

import { useMedia } from '../../context/MediaContext';
import { formatMediaDetailName } from '../../utils/General';
import Alert from '../Alert';
import './Gallery.scss';
import { GalleryProps } from './types';

const Gallery: React.FC<GalleryProps> = () => {
  const [fileToBeDeleted, setFileToBeDeleted] = useState<string>();
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  // const [numPages, setNumPages] = useState<number>(0);
  // const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
  //   setNumPages(numPages);
  // };
  //PDF VIEWER
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  //

  const {
    tempFile,
    setTempFile,
    setConfirmFile,
    media,
    fileToBeViewed,
    deleteCurrentMediaFile,
    showMediaState,
    setShowMediaState,
    comprobanteMedia,
    setComprobanteMedia,
  } = useMedia();

  const fileToBeViewedOk = media.find(
    (element) => element.tag.detail === fileToBeViewed
  );

  useEffect(() => {
    if (tempFile) {
      setShowMediaState('tempFile');
    }
  }, [tempFile]);

  useEffect(() => {
    if (!tempFile && !media.length && !comprobanteMedia?.length) {
      setShowMediaState(undefined);
    }
  }, [media]);

  useEffect(() => {
    !fileToBeViewedOk && setShowMediaState(undefined);
  }, [fileToBeViewedOk]);

  return (
    <IonModal
      cssClass={'photo-gallery'}
      isOpen={showMediaState !== undefined}
      onDidDismiss={() => {
        setShowMediaState(undefined);
        setComprobanteMedia(undefined);
      }}
    >
      <IonToolbar>
        <IonButtons slot="start">
          <IonIcon
            icon={closeSharp}
            onClick={() => {
              setShowMediaState(undefined);
              if (showMediaState === 'tempFile') {
                setTempFile(undefined);
              }
            }}
          />
        </IonButtons>
        <IonTitle>
          <div>
            {showMediaState === 'tempFile' && 'Nuevo archivo'}
            {showMediaState === 'viewFile' &&
              fileToBeViewedOk &&
              formatMediaDetailName(fileToBeViewedOk.tag.detail)}
            {showMediaState === 'gallery' && 'Comprobantes de pago'}
          </div>
        </IonTitle>
      </IonToolbar>

      <IonGrid>
        {showMediaState === 'tempFile' && (
          <>
            <IonRow>
              <IonCol>
                {tempFile?.fileName.endsWith('.jpeg') ? (
                  <img src={tempFile.webviewPath} alt="Image preview" />
                ) : (
                  <div className="pdf-container">
                    <div className="pdf-wrapper">
                      <Document
                        file={tempFile?.webviewPath}
                        onLoadError={(error) =>
                          console.log('Error loading PDF:', error)
                        }
                        className="pdf-document"
                      >
                        <Page pageNumber={1} />
                      </Document>
                    </div>
                  </div>
                )}
              </IonCol>
            </IonRow>

            <IonRow className="ion-margin-top">
              <IonCol>
                <IonButton
                  mode="ios"
                  expand="block"
                  onClick={() => {
                    setShowMediaState(undefined);
                    setConfirmFile(true);
                  }}
                >
                  Añadir archivo
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        )}

        {showMediaState === 'viewFile' && fileToBeViewedOk && (
          <>
            <IonRow>
              <IonCol>
                {fileToBeViewedOk.fileName.endsWith('.jpeg') ? (
                  <img src={fileToBeViewedOk.webviewPath} alt="Image preview" />
                ) : (
                  <div className="pdf-container">
                    <div className="pdf-wrapper">
                      <Document
                        file={fileToBeViewedOk?.webviewPath}
                        onLoadError={(error) =>
                          console.log('Error loading PDF:', error)
                        }
                        className="pdf-document"
                      >
                        <Page pageNumber={1} />
                      </Document>
                    </div>
                  </div>
                )}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  mode="ios"
                  expand="block"
                  color="danger"
                  fill="outline"
                  onClick={() => {
                    setFileToBeDeleted(fileToBeViewedOk.fileName);
                    setShowDeleteAlert(true);
                  }}
                >
                  {fileToBeViewedOk.tag.type === 'comprobante'
                    ? 'Descartar imagen'
                    : 'Eliminar imagen'}
                </IonButton>

                <IonButton
                  mode="ios"
                  expand="block"
                  onClick={() => setShowMediaState(undefined)}
                >
                  Regresar
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        )}

        {showMediaState === 'gallery' && (
          <div className="gallery">
            <IonRow>
              {comprobanteMedia?.map((element, index) => (
                <IonCol size="6" key={index}>
                  {/* Discrimina por file extension */}
                  {element.file.startsWith('/9j/') ||
                  element.file.startsWith('iVBORw0') ? (
                    <>
                      <img
                        src={`data:image/jpeg;base64,${element.file}`}
                        alt={`Photo ${index}`}
                      />
                    </>
                  ) : (
                    <>
                      <div className="pdf-container">
                        <div className="pdf-wrapper">
                          <Document
                            file={`data:application/pdf;base64,${element.file}`}
                            onLoadError={(error) =>
                              console.log('Error loading PDF:', error)
                            }
                            className="pdf-document"
                          >
                            <Page pageNumber={1} />
                          </Document>
                        </div>
                      </div>
                    </>
                  )}
                </IonCol>
              ))}
            </IonRow>
          </div>
        )}
      </IonGrid>

      {/* Delete element */}
      <Alert
        isOpen={showDeleteAlert}
        setIsOpen={setShowDeleteAlert}
        subHeader={'¿Desea eliminar el archivo?'}
        buttonsLabel={{
          primary: 'Conservar',
          secondary: 'Eliminar',
        }}
        actions={{
          primary: () => setShowDeleteAlert(false),
          secondary: () =>
            fileToBeDeleted && deleteCurrentMediaFile(fileToBeDeleted),
        }}
        deleteStyle
      />
    </IonModal>
  );
};

export default Gallery;
