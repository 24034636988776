import axiosInstance from '.';
import { GET_DATOS } from './constants';
import { AxiosResponse } from "axios";
import { Datos } from "../interfaces/Datos";

export const getDatos = async (): Promise<Datos[]> => {

    try {
        const { data: datos }: AxiosResponse<Datos[]> = await axiosInstance.get(GET_DATOS);
        return datos;
    } catch (e) {
        console.error(e);
        throw e;
    }
};