import { useState } from 'react';
import { useCamera } from './useCamera';

export const useDocumentacion = () => {
  const { takePicture } = useCamera();
  const [isUpdating, setUpdating] = useState<boolean>(false);

  const getImgFile = async () => {
    try {
      setUpdating(true);
      const pic = await takePicture('');
      setUpdating(false);
      return pic;
    } catch (error) {
      console.log('errorCamera:', error);
      throw error;
    }
  };
  return { getImgFile, isUpdating };
};
