import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonPopover,
  IonSpinner,
} from '@ionic/react';

import {
  validationName,
  validationNumber,
} from '../../assets/validations/validationRegex';
import { useMedia } from '../../context/MediaContext';
import {
  useComprobante,
  useGetComprobantes,
} from '../../hooks/useComprobanteCliente';
import { ComprobantePagoReq } from '../../interfaces/ComprobatePagoReq';
import FileUploader from '../FileUploader';
import FormItem from '../FormItem';
import './ComprobantePagoForm.scss';
import { ComprobantePagoFormProps } from './types';

const ComprobantePagoForm: React.FC<ComprobantePagoFormProps> = ({
  popoverState,
  setShowPopover,
  vetForm,
}) => {
  const { handleSubmit, control, errors, watch } =
    useForm<ComprobantePagoReq>();
  const { media, elementExistInMedia } = useMedia();
  const { onFileChange, isLoading } = useComprobante();
  const { mutate } = useGetComprobantes(Number(vetForm.id));

  const concepto = watch('concepto');
  const monto = watch('monto');
  const detail = `comprobante_${concepto}_$${monto}`;

  const onSubmit = async (values: ComprobantePagoReq) => {
    const exist = elementExistInMedia(detail);
    if (exist) {
      const comprobante = media.find((element) =>
        element.fileName.includes(detail)
      );
      values.comprobante = comprobante?.webviewPath ?? '';
    }
    values.vetFormId = Number(vetForm.id);
    await onFileChange(values, mutate);
    setShowPopover({ showPopover: false, event: undefined });
  };

  return (
    <IonPopover
      mode="ios"
      cssClass="comprobante-pago-form-popover"
      event={popoverState.event}
      isOpen={popoverState.showPopover}
      onDidDismiss={() =>
        setShowPopover({ showPopover: false, event: undefined })
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} className="comprobante-pago-form">
        <FormItem
          name="concepto"
          control={control}
          errors={errors}
          render={({ onChange, name, value }) => (
            <>
              <IonItem lines="inset" className="ion-no-padding">
                <IonLabel position="floating">Concepto</IonLabel>
                <IonInput
                  type="text"
                  name={name}
                  value={value}
                  onIonChange={(e) => {
                    onChange(e);
                  }}
                />
              </IonItem>
            </>
          )}
          rules={{
            required: {
              value: true,
              message: 'Por favor, complete este campo.',
              pattern: validationName,
            },
          }}
        />

        <FormItem
          name="monto"
          control={control}
          errors={errors}
          render={({ onChange, name, value }) => (
            <>
              <IonItem lines="inset" className="ion-no-padding">
                <IonLabel position="floating">Monto</IonLabel>
                <IonInput
                  type="number"
                  name={name}
                  value={value}
                  onIonChange={(e) => {
                    onChange(e);
                  }}
                />
              </IonItem>
            </>
          )}
          rules={{
            required: {
              value: true,
              message: 'Por favor, complete este campo.',
              pattern: validationNumber,
            },
          }}
        />

        <FileUploader
          tag={{
            type: 'comprobante',
            detail: detail,
          }}
          placeholder="Comprobante"
          disabled={!concepto || !monto}
          hasData={media.some((element) => element.fileName.includes(detail))}
        />

        <div className="ion-margin-top">
          <IonButton
            type="submit"
            disabled={
              !concepto ||
              !monto ||
              !media.some((element) => element.fileName.includes(detail))
            }
            expand="block"
          >
            {isLoading ? <IonSpinner color="light" /> : 'Enviar'}
          </IonButton>
        </div>
      </form>
    </IonPopover>
  );
};

export default ComprobantePagoForm;
