import React, { useEffect, useState } from 'react';

import {
  IonIcon,
  IonModal,
  IonButton,
  IonPopover,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
} from '@ionic/react';
import 'animate.css';
import { ellipseSharp, pencilSharp } from 'ionicons/icons';

import PawPrint from '../../assets/pawprint.png';
import Food from '../../assets/pet-food1.png';
import CatPlaceholder from '../../assets/placeholders/cat.svg';
import DogPlaceholder from '../../assets/placeholders/dog.svg';
import Weight from '../../assets/weight.png';
import { useSubmitNewFood } from '../../hooks';
import { RemovePetFood, UpdateFoodRes } from '../../interfaces/Food';
import ToastAlert from '../ToastAlert';
import './FoodInfoItemModal.scss';
import { FoodInfoItemModalProps } from './types';

const FoodInfoItemModal: React.FC<FoodInfoItemModalProps> = ({
  open,
  setShowModal,
  pets,
  food,
  mutateFood,
}) => {
  const { total } = food;
  const { nombre, marca, presentaciones } = food.alimento;
  const { updatePetFood, removePetFromFood } = useSubmitNewFood();

  const [newConsumers, setNewConsumers] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [deleteConsumers, setDeleteConsumers] = useState<number[]>([]);
  const [pesoAlimento, setPesoAlimento] = useState<number | undefined>();
  const [sucessToast, setSucessToast] = useState<boolean>(false);
  const [requiredConsumerToast, setRequiredConsumerToast] =
    useState<boolean>(false);

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const [popoverState2, setShowPopover2] = useState({
    showPopover: false,
    event: undefined,
  });

  const consumersName = pets?.filter((pet) =>
    food.stocksAlimentoMascotas.some((e) => e.mascotaId === pet.id)
  );

  function placeholder(animal) {
    return animal === 'Perro' ? DogPlaceholder : CatPlaceholder;
  }

  const requiredConsumer: boolean | undefined =
    pets
      ?.filter((pet) =>
        food.stocksAlimentoMascotas.some((e) => e.mascotaId === pet.id)
      )
      .every((pet) => deleteConsumers.includes(pet.id)) &&
    Object.keys(newConsumers).length === 0;

  const consumersBtnState =
    Object.keys(newConsumers).length === 0 &&
    (Object.keys(deleteConsumers).length === 0 || requiredConsumer);

  function consumers(): JSX.Element {
    const petsCoincidentes = pets?.filter((pet) =>
      food.stocksAlimentoMascotas.some((e) => e.mascotaId === pet.id)
    );

    const petsNoCoincidentes = pets?.filter(
      (pet) =>
        !food.stocksAlimentoMascotas.some((e) => e.mascotaId === pet.id) &&
        petsCoincidentes?.some((e) => e.animal === pet.animal)
    );

    return (
      <div className="pets ion-padding-horizontal">
        {petsCoincidentes?.map((e, index) => (
          <div
            key={index}
            className={
              deleteConsumers.includes(e.id)
                ? 'e-disabled'
                : 'e animate__animated animate__headShake'
            }
            onClick={() => deleteConsumersHandler(e.id)}
          >
            <img
              src={
                e.img_link
                  ? `data:image/jpeg;base64,${e.img_link}`
                  : placeholder(e.animal)
              }
              alt={e.nombre}
            />
            <p>{e.nombre}</p>
            {/* {!deleteConsumers.includes(e.id) ? <IonIcon icon={checkmarkCircleSharp} color="primary" /> : <IonIcon icon={trashSharp} color="danger" />} */}
          </div>
        ))}

        {petsNoCoincidentes?.map((e, index) => (
          <div
            key={index}
            className={
              !newConsumers[e.id]
                ? 'e-disabled'
                : 'e animate__animated animate__headShake'
            }
            onClick={() => newConsumersHandler(e.id)}
          >
            <img
              src={
                e.img_link
                  ? `data:image/jpeg;base64,${e.img_link}`
                  : placeholder(e.animal)
              }
              alt={e.nombre}
            />
            <p>{e.nombre}</p>
          </div>
        ))}
        <IonButton
          mode="ios"
          expand="block"
          disabled={consumersBtnState}
          onClick={() => {
            addConsumers();
            removeConsumers();
            setShowPopover({ showPopover: false, event: undefined });
          }}
        >
          Confirmar
        </IonButton>
      </div>
    );
  }

  const newConsumersHandler = (petId: number) => {
    setNewConsumers((prevState) => {
      const updatedState = { ...prevState };

      if (updatedState[petId]) {
        // Si el elemento ya existe, elimínalo
        delete updatedState[petId];
      } else {
        // Si el elemento no existe, agrégalo
        updatedState[petId] = true;
      }

      return updatedState;
    });
  };

  const deleteConsumersHandler = (petId: number) => {
    // Si el ID ya está en el array, elimínalo
    if (deleteConsumers.includes(petId)) {
      const filteredConsumers = deleteConsumers.filter(
        (item) => item !== petId
      );
      setDeleteConsumers(filteredConsumers);
    } else {
      // Si el ID no está en el array, agrégalo
      setDeleteConsumers([...deleteConsumers, petId]);
    }
  };

  const addConsumers = async () => {
    const body = {
      alimentoId: food?.alimento.id,
      mascotas: Object.keys(newConsumers).map(Number),
    };
    const rta = await updatePetFood(body as UpdateFoodRes, food.id, mutateFood);
    if (!rta) {
    }
  };

  const removeConsumers = async () => {
    const body = {
      alimentoId: food?.alimento.id,
      mascotas: deleteConsumers,
    };

    const rta = await removePetFromFood(body as RemovePetFood, food.id);
    if (rta) {
      setSucessToast(true);
    }
  };

  const updateKilos = async (kilos: number) => {
    const body = {
      alimentoId: food?.alimento.id,
      kilos: kilos,
    };
    const rta = await updatePetFood(body as UpdateFoodRes, food.id, mutateFood);
    if (rta) {
      setSucessToast(true);
    }
  };

  useEffect(() => {
    requiredConsumer && setRequiredConsumerToast(true);
  }, [requiredConsumer]);

  return (
    <div className="food-info-item-modal">
      <IonModal
        mode="md"
        isOpen={open}
        onDidDismiss={() => setShowModal(false)}
        cssClass="food-info-item-modal__edit-food-modal animate__animated animate__zoomIn"
      >
        <div className="ion-padding">
          <div className="header-img">
            <img src={Food} />
          </div>
          <div className="food-header-info ion-margin-vertical">
            <div className="nombre-marca-box">
              <div className="nombre">{nombre}</div>
              <div className="marca">{marca}</div>
            </div>
            {/* <FoodBag current={current} presentacion={total} /> */}
          </div>
          <div className="ion-padding">
            <div className="option">
              <IonIcon icon={ellipseSharp} color="primary" />
              <p>
                <b>Come: </b>
                {consumersName?.map((e, index) => (
                  <span key={index}>
                    {index > 0 && index < consumersName.length - 1 ? ', ' : ''}
                    {index === consumersName.length - 1 &&
                    consumersName.length > 1
                      ? ' y '
                      : ''}
                    {e.nombre}
                    {index === consumersName.length - 1 &&
                    consumersName.length > 1
                      ? ''
                      : ''}
                  </span>
                ))}
              </p>
              <IonIcon
                icon={pencilSharp}
                className="edit"
                onClick={() =>
                  setShowPopover({ showPopover: true, event: undefined })
                }
              />
            </div>
            <div className="option">
              <IonIcon icon={ellipseSharp} color="primary" />
              <p>
                <b>Presentación: </b>
                {total} Kg
              </p>
              <IonIcon
                icon={pencilSharp}
                className="edit"
                onClick={() =>
                  setShowPopover2({ showPopover: true, event: undefined })
                }
              />
            </div>
          </div>
          <div className="ion-padding">
            <IonButton
              mode="ios"
              expand="block"
              onClick={() => setShowModal(false)}
            >
              Cerrar
            </IonButton>
          </div>
        </div>
      </IonModal>

      <IonPopover
        mode="ios"
        cssClass="foodBag__popover"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => {
          setShowPopover({ showPopover: false, event: undefined });
          setNewConsumers({});
          setDeleteConsumers([]);
        }}
      >
        <div className="ion-padding-bottom">
          <div className="header-img">
            <img src={PawPrint} />
          </div>
          {consumers()}
        </div>
      </IonPopover>

      <IonPopover
        mode="ios"
        cssClass="foodBag__popover"
        event={popoverState2.event}
        isOpen={popoverState2.showPopover}
        onDidDismiss={() => {
          setShowPopover2({ showPopover: false, event: undefined });
          setPesoAlimento(undefined);
        }}
      >
        <div className="ion-padding-bottom">
          <div className="header-img">
            <img src={Weight} />
          </div>
          <IonRadioGroup
            value={pesoAlimento}
            onIonChange={(e) => setPesoAlimento(e.detail.value)}
          >
            {presentaciones.map((e) => (
              <IonItem key={e} lines="none">
                <IonLabel>{e} Kg</IonLabel>
                <IonRadio slot="start" mode="md" value={e} />
              </IonItem>
            ))}
            <div className="ion-margin-top ion-padding-horizontal">
              <IonButton
                mode="ios"
                expand="block"
                disabled={!pesoAlimento}
                onClick={() => {
                  {
                    pesoAlimento && updateKilos(pesoAlimento);
                  }
                  setShowPopover2({ showPopover: false, event: undefined });
                }}
              >
                Confirmar
              </IonButton>
            </div>
          </IonRadioGroup>
        </div>
      </IonPopover>

      <ToastAlert
        isOpen={sucessToast}
        setShowToast={setSucessToast}
        message="Alimento actualizado"
        color="success"
        duration={3000}
      />

      <ToastAlert
        isOpen={requiredConsumerToast}
        setShowToast={setRequiredConsumerToast}
        message="Ups... Al menos una mascota debe comer del alimento!"
        color="warning"
        duration={3000}
      />
    </div>
  );
};

export default FoodInfoItemModal;
