export type PersonalDataReq = {
  nombre: string;
  apellido: string;
  dni: string;
  tel_celular: string;
  fecha_nacimiento: Date;
  genero: 'M' | 'F';
  //TODO
  email?: string; 
};

export function getEmptyPersonalData(): PersonalDataReq {
  return {
    nombre: '',
    apellido: '',
    dni: '',
    tel_celular: '',
    fecha_nacimiento: new Date(),
    genero: 'M',
  };
}

export type AddressDataReq = {
  direccion: string;
  numero: number;
  piso?: number;
  departamento?: string;
  provincia: string;
  localidadId?: number;
  localidad?: string;
};

export function getEmptyAddressData(): AddressDataReq {
  return {
    direccion: '',
    numero: 0,
    piso: undefined,
    departamento: undefined,
    provincia: '',
    localidadId: 0,
    localidad: '',
  };
}

export type PersonalAndAddressDataReq = {
  datos_persona: PersonalDataReq;
  datos_domicilio: AddressDataReq;
};

export type PersonalDataModificationRequest = {
  nombre: string | null;
  apellido: string | null;
  dni: string | null;
  tel_celular: string | null;
  fecha_nacimiento: Date | null;
  genero: string | null;
  direccion: string | null;
  numero: string | null;
  piso: string | null;
  departamento: string | null;
  provincia: string | null;
  localidad: number | null;
  // localidadId: number | null,
};

export function getEmptyPersonalDataModificationRequest(): PersonalDataModificationRequest {
  return {
    nombre: null,
    apellido: null,
    dni: null,
    tel_celular: null,
    fecha_nacimiento: null,
    genero: null,
    direccion: null,
    numero: null,
    piso: null,
    departamento: null,
    provincia: null,
    localidad: null,
    // localidadId: null,
  };
}

export type PersonalDataModificationRequestToSend = {
  mascotaId: number;
  nombre: string | null | undefined;
  apellido: string | null | undefined;
  dni: string | null | undefined;
  telefono: string | null | undefined;
  fechaNac: Date | null | undefined;
  sexo: string | null | undefined;
  direccion: string | null | undefined;
  altura: string | null | undefined;
  piso: string | null | undefined;
  dpto: string | null | undefined;
  provincia: string | null | undefined;
  localidad: string | null | undefined;
  // localidadId: number | null | undefined;
  [key: string]: string | number | Date | null | undefined;  // Propiedad indexada
}
