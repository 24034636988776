import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import {
  IonButton,
  IonIcon,
  IonLabel,
  IonRow,
  IonCol,
  IonGrid,
  IonRadioGroup,
  IonRadio,
  IonSpinner,
  IonPopover,
  IonText,
} from '@ionic/react';
import 'animate.css';
import { addCircleOutline } from 'ionicons/icons';

import American from '../../assets/tarjetas/American_Express.svg';
import Default from '../../assets/tarjetas/DefaultCard.svg';
import Diners from '../../assets/tarjetas/Diners.svg';
import Cbu from '../../assets/tarjetas/cbu.png';
import Mastercard from '../../assets/tarjetas/mastercard.svg';
import Naranja from '../../assets/tarjetas/naranja.svg';
import Visa from '../../assets/tarjetas/visa.svg';
import { useEnter } from '../../hooks/useEnter';
import { PetAddForm2Type } from '../../interfaces/forms/PetForm2';
import AddPayment from '../AddPayment';
import FormItem from '../FormItem';
import './FormAddPet_2.scss';
import { FormAddPet_2Props } from './types';

const FormAddPet_2: React.FC<FormAddPet_2Props> = ({
  prevForm,
  setAddPetForm2,
  preSubmit,
  form2,
  plans,
  datosPago,
  currentPlan,
  loading,
}) => {
  const [newMedioPago, setNewMedioPago] = useState(false);
  const newMedioPagoDiv = useRef<any>(null);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const { control, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: { form2 },
  });

  const planWatcher = watch('plan');
  const medioDePagoWatcher = watch('medioDePago');

  useEffect(() => {
    console.log('PW', planWatcher);
    console.log('MDP', medioDePagoWatcher);
  }, [planWatcher, medioDePagoWatcher]);

  const { ref, handleEnter } = useEnter();

  const onSubmit = (values: PetAddForm2Type) => {
    preSubmit(values);
  };

  useEffect(() => {
    if (datosPago && datosPago.length > 0)
      control.setValue('medioDePago', datosPago[datosPago.length - 1].id);
  }, [datosPago?.length]);

  useEffect(() => {
    if (newMedioPago && newMedioPagoDiv.current) {
      newMedioPagoDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [newMedioPago]);

  useEffect(() => {
    if (plans && currentPlan) {
      const planNumber = currentPlan.plan.match(/\d+/)?.[0];
      if (planNumber) {
        setValue('plan', parseInt(planNumber));
      }
    }
  }, [plans, currentPlan, setValue]);

  const getCardImg = (t: string) => {
    switch (t) {
      case 'VISA':
        return Visa;
      case 'MASTERCARD':
        return Mastercard;
      case 'CBU Caja de Ahorro':
        return Cbu;
      case 'CBU Cuenta Corriente':
        return Cbu;
      case 'NARANJA':
        return Naranja;
      case 'AMERICAN EXPRESS':
        return American;
      case 'Dinners Club':
        return Diners;
      default:
        return Default;
    }
  };

  const formatCreditCardNumber = (creditCardNumber: string) => {
    return creditCardNumber.slice(-4);
  };

  console.log('currentPlan: ,: ', currentPlan);

  return (
    <>
      <div hidden={newMedioPago}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyUp={handleEnter}
          className="form-add-pet-2"
        >
          <IonGrid className="ion-no-padding ion-margin">
            <IonRow className="form-add-pet-2__table-title">
              <IonCol>Plan</IonCol>
            </IonRow>

            <IonRow>
              <IonCol
                offset="4"
                size="3"
                className="form-add-pet-2__column-title"
              >
                Precio
              </IonCol>
              <IonCol className="form-add-pet-2__column-title ion-text-end">
                Valor asegurado
              </IonCol>
            </IonRow>

            <FormItem
              render={({ onChange, name, value }) => (
                <IonRadioGroup
                  name={name}
                  value={value}
                  onIonChange={(e) => {
                    onChange(e.detail.value);
                  }}
                >
                  {plans
                    // Ordena los planes de menor a mayor costo
                    .sort((a, b) => a.costo - b.costo)
                    .map((p, index) => (
                      <IonRow
                        key={index}
                        className="form-add-pet-2__row-border form-add-pet-2__selected-row"
                      >
                        <IonCol>
                          <IonRadio mode="md" value={p.id} />
                        </IonCol>
                        <IonCol>
                          <IonLabel>$ {p.costo}</IonLabel>
                        </IonCol>
                        <IonCol className="ion-text-end">
                          <IonLabel>
                            ${' '}
                            {p.suma_asegurada
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                          </IonLabel>
                        </IonCol>
                      </IonRow>
                    ))}
                </IonRadioGroup>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor, elija un plan de cobertura',
                },
              }}
              control={control}
              name="plan"
              errors={errors}
            />
          </IonGrid>

          <div className="ion-margin-start">
            <IonText color="medium">
              <i>Todos los planes ofrecen $21.500 en vacunas</i>
            </IonText>
          </div>

          <IonGrid className="ion-no-padding ion-margin">
            <IonRow className="form-add-pet-2__table-title">
              <IonCol>Medio de pago</IonCol>
            </IonRow>

            <FormItem
              render={({ onChange, name, value }) => (
                <IonRadioGroup
                  name={name}
                  value={value}
                  onIonChange={(e) => {
                    onChange(e.detail.value);
                  }}
                >
                  {datosPago !== undefined ? (
                    datosPago.map((dp, index) => (
                      <IonRow
                        key={index}
                        className="form-add-pet-2__row-border form-add-pet-2__selected-row"
                      >
                        <IonCol size="4">
                          <IonRadio mode="md" value={dp.id} />
                        </IonCol>
                        <IonCol size="2">
                          <img src={getCardImg(dp.tipo)} />
                        </IonCol>
                        <IonCol className="ion-text-end">
                          Finaliza:{' '}
                          <b>{formatCreditCardNumber(dp.numero_pago)}</b>
                        </IonCol>
                      </IonRow>
                    ))
                  ) : (
                    <IonSpinner />
                  )}
                </IonRadioGroup>
              )}
              rules={{
                required: {
                  value: true,
                  message: 'Por favor, elija un medio de pago',
                },
              }}
              control={control}
              name="medioDePago"
              errors={errors}
            />
          </IonGrid>
          <div className="ion-margin">
            <div className="ion-margin-bottom">
              <IonButton
                expand="block"
                mode="ios"
                fill="outline"
                color="dark"
                onClick={() => setNewMedioPago(true)}
                className="form-add-pet-2__newMedioPago-btn"
              >
                <IonIcon icon={addCircleOutline} />
                Agregar medio de pago
              </IonButton>
            </div>

            <IonButton
              type="submit"
              disabled={loading}
              ref={ref}
              mode="ios"
              expand="block"
            >
              {loading && <IonSpinner name="crescent" slot="end" />}
              Confirmar
            </IonButton>
          </div>
        </form>
      </div>
      <div ref={newMedioPagoDiv} hidden={!newMedioPago}>
        <AddPayment setNewMedioPago={setNewMedioPago} />
      </div>
    </>
  );
};

export default FormAddPet_2;
