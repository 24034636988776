import React, { useState } from 'react';

import { IonCol, IonGrid, IonRow, IonSpinner, IonText } from '@ionic/react';

import Add from '../../assets/plus.png';
import { useMedia } from '../../context/MediaContext';
import { useGetComprobantes } from '../../hooks/useComprobanteCliente';
import ComprobantePagoForm from '../ComprobantePagoForm';
import ToastMsg from '../ToastMsg';
import './VetFormComprobanteInput.scss';

const VetFormComprobanteInput: React.FC<any> = ({ vetForm }) => {
  const {
    setShowMediaState,
    setComprobanteMedia,
    showSuccessfulComprobanteToast,
    setShowSuccessfulComprobanteToast,
  } = useMedia();

  const { data: comprobantes, isValidating } = useGetComprobantes(
    Number(vetForm.id)
  );

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const handleButtonClick = () => {
    if (comprobantes?.length > 0) {
      setComprobanteMedia(comprobantes);
      setShowMediaState('gallery');
    } else {
      setShowPopover({ showPopover: true, event: undefined });
    }
  };

  return (
    <div
      className="vet-form-comprobante-input"
      onClick={(e) => e.stopPropagation()}
    >
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol
            size="2"
            onClick={() =>
              setShowPopover({ showPopover: true, event: undefined })
            }
          >
            <img src={Add} />
          </IonCol>
          <IonCol offset="1" onClick={() => handleButtonClick()}>
            <div className="comprobante-box">
              <p>Comprobante de pago</p>

              {isValidating ? (
                <IonSpinner color="dark" />
              ) : (
                <IonText color="medium">
                  ({comprobantes?.length ?? 0} cargado
                  {comprobantes?.length !== 1 ? 's' : ''})
                </IonText>
              )}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <ComprobantePagoForm
        popoverState={popoverState}
        setShowPopover={setShowPopover}
        vetForm={vetForm}
      />

      <ToastMsg
        isOpen={showSuccessfulComprobanteToast}
        setIsOpen={setShowSuccessfulComprobanteToast}
        message={`Comprobante adjuntado con exito.`}
        color={'success'}
      />
    </div>
  );
};

export default VetFormComprobanteInput;
