import React, { createContext, useContext, useEffect } from 'react';
import { useUserData } from '../hooks/useUserData';
import { AuthContext } from './AuthContext';

interface UserContextType {
  user: {
    data: any; // Cambia `any` por el tipo específico si lo tienes
    mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
    error: any;
    isValidating: boolean;
  };
}

const UserContext = createContext<UserContextType | null>(null);

export const UserContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { authStatus } = useContext(AuthContext);
  const { data: userData, isValidating: loading, error, mutate, isValidating } = useUserData();

  useEffect(() => {
    if (!loading && !error) {
      mutate(); // Asegúrate de que mutate esté disponible y sea válido
    }
  }, [authStatus]);

  const contextValue = {
    user: {
      data: userData,
      mutate,
      error,
      isValidating,
    },
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) throw Error('User context unavailable');
  return context;
};
