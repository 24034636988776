import { useState } from 'react';

import useSWR from 'swr';

import { addComprobanteCliente, getComprobantes } from '../axios/comprobantes';
import { GET_COMPROBANTES } from '../axios/constants';
import { useMedia } from '../context/MediaContext';

export const useComprobante = () => {
  const { setShowSuccessfulComprobanteToast } = useMedia();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setComprobanteMedia } = useMedia();

  function base64ToBlob(base64) {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/octet-stream' }); // Opcionalmente puedes usar el mime type adecuado
  }

  function urlToBase64(url: string): Promise<string> {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
          reader.onerror = () => {
            reject(new Error('Failed to convert blob to base64'));
          };
          reader.readAsDataURL(blob);
        });
      })
      .catch((error) => {
        throw new Error(
          `Failed to fetch and convert to base64 from URL: ${error}`
        );
      });
  }

  const convertToBase64 = async (input: string): Promise<string> => {
    if (input.startsWith('data:')) {
      return input;
    } else if (input.startsWith('blob:')) {
      return urlToBase64(input);
    } else {
      throw new Error('Unsupported URL type');
    }
  };

  const onFileChange = async (values, mutate?) => {
    if (!values.comprobante) {
      console.error('Comprobante is not defined');
      return;
    }

    let base64: string;
    try {
      base64 = await convertToBase64(values.comprobante);
    } catch (error) {
      console.error('Failed to convert photo to base64:', error);
      return;
    }

    const formData = new FormData();

    // Convertir base64 a Blob y añadir al FormData
    const fileBlob = base64ToBlob(base64);
    formData.append('comprobante', fileBlob, 'comprobante.jpg');

    formData.append('concepto', values.concepto);
    formData.append('monto', values.monto);
    formData.append('vetFormId', values.vetFormId);

    try {
      setIsLoading(true);
      const res = await addComprobanteCliente(formData);
      if (res) {
        const newComprobantes = await mutate(GET_COMPROBANTES);
        setComprobanteMedia(newComprobantes); 
        setShowSuccessfulComprobanteToast(true);
      }
    } catch (error) {
      console.error('Failed to add comprobante:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return { onFileChange, isLoading };
};

export const useGetComprobantes = (vetFormId: number) => {
  const { data, isValidating, error, mutate } = useSWR(
    [GET_COMPROBANTES, vetFormId],
    (url) => getComprobantes(url, vetFormId),
    { revalidateOnFocus: false, dedupingInterval: 1500 }
  );

  return { data, error, isValidating, mutate };
};
