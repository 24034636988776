import axios from '.';
import {
  ADD_CBU_FILE,
  ADD_DNI_FILE,
  ADD_LIBRETA_VACUNACION_FILE,
  ADD_EXTRA_FILE,
} from './constants';

export const addDni = async (formData: FormData) => {
  console.log("addDNI")
  await axios.post(ADD_DNI_FILE, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const addLibretaVacunacion = async (formData: FormData) => {
  console.log("addLibreta")
  await axios.post(ADD_LIBRETA_VACUNACION_FILE, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const addCbu = async (formData: FormData) => {
  console.log("addCBU")
  await axios.post(ADD_CBU_FILE, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const addExtra = async (formData: FormData) => {
  console.log("addDocExtra")
  await axios.post(ADD_EXTRA_FILE, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
