import { AxiosResponse } from 'axios';

import axios from '.';
import { ADD_COMPROBANTE_CLIENTE_FILE } from './constants';

export const addComprobanteCliente = async (formData: FormData): Promise<boolean> => {
  try {
    const response = await axios.post(ADD_COMPROBANTE_CLIENTE_FILE, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // Verificar si el código de respuesta es 2xx
    return response.status >= 200 && response.status < 300;
  } catch (error) {
    console.error('Failed to add comprobante:', error);
    return false;
  }
};

export const getComprobantes = async (url: string, vetFormId: number) => {
  try {
    const { data }: AxiosResponse<any> = await axios.get(
      url,
      {
        params: {
          vetFormId,
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};
