import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';

import {
  IonContent,
  IonIcon,
  IonPage,
  IonSpinner,
  useIonRouter,
} from '@ionic/react';
import 'animate.css';
import { heart } from 'ionicons/icons';
import moment from 'moment';

import Health from '../../assets/health-insurance.png';
import MedicalRecord from '../../assets/medical-record.png';
import Nutrition from '../../assets/pet-food3.png';
import CatPlaceholder from '../../assets/placeholders/cat.svg';
import DogPlaceholder from '../../assets/placeholders/dog.svg';
import Shield from '../../assets/shield.png';
import Vaccine from '../../assets/vaccine.png';
import GestionSeguro from '../../components/GestionSeguro';
import NavigationBar from '../../components/NavigationBar';
import NutricionMenu from '../../components/NutricionMenu';
import PetDetailsOption from '../../components/PetDetailsOption';
import ToastAlert from '../../components/ToastAlert';
import { useMedia } from '../../context/MediaContext';
import { useGetPet, useProfilePic } from '../../hooks';
import { useDatos } from '../../hooks/useDatos';
import './PetDetailsPage.scss';

type ParamsType = {
  id: string;
};

const PetDetailsPage: React.FC = () => {
  const { push } = useIonRouter();
  const { id: petId } = useParams<ParamsType>();
  const { pet, mutate } = useGetPet(petId);
  const { animal, img_link, nombre, sexo, fecha_nacimiento, raza } = pet || {};

  const {
    photos,
    showFileSizeValidationToast,
    setShowFileSizeValidationToast,
  } = useMedia();
  const profilePicRef = useRef<string | undefined>();
  const prevPhotosRef = useRef(photos);

  const { updateProfilePic, isUpdating } = useProfilePic();
  const datos = useDatos();

  const [nutricionPopoverState, setNutricionPopoverState] = useState({
    showPopover: false,
    event: undefined,
  });

  const [gestionSeguroPopoverState, setGestionSeguroPopoverState] = useState({
    showPopover: false,
    event: undefined,
  });

  const [vacunasLink, setLink] = useState<string>(
    'https://api.whatsapp.com/send?phone=5491121808888'
  );
  const [vacunasTexto, setTexto] = useState<string>(
    'Vacuna, guardería o eutanasia'
  );
  const [vacunasDescripcion, setDescripcion] = useState<string>(
    'Gestioná un reintegro de vacuna, guardería o eutanasia'
  );

  const placeholder =
    animal === 'Perro'
      ? DogPlaceholder
      : animal === 'Gato'
      ? CatPlaceholder
      : undefined;

  function calculoEdad() {
    const fechaNacimiento = moment(fecha_nacimiento);
    const fechaActual = moment();

    const years = fechaActual.diff(fechaNacimiento, 'years');
    const months = fechaActual.diff(fechaNacimiento, 'months');

    if (years >= 1) {
      return `${years} años`;
    } else {
      return `${months} meses`;
    }
  }

  const formattedSexo = sexo === 'M' ? 'Macho' : 'Hembra';

  function goTo(path: string) {
    push(`/tabs/${path}/${petId}`, 'forward', 'push');
  }

  function redirectToUrl(url: string) {
    setTimeout(() => {
      window.open(url, '_blank');
    }, 100);
  }

  const tag = {
    type: 'pet',
    detail: `petProfilePic${pet?.nombre}`,
  };

  async function handleProfilePicUpdate(sourceType: string) {
    // console.log('Updating profile picture');
    await updateProfilePic(sourceType, petId);
    mutate();
  }

  useEffect(() => {
    const currentPhotos = prevPhotosRef.current;

    if (currentPhotos !== photos) {
      prevPhotosRef.current = photos;

      const petProfilePic = photos.find(
        (photo) => photo.tag.detail === `petProfilePic${nombre}`
      );

      if (petProfilePic?.webviewPath !== profilePicRef.current) {
        profilePicRef.current = petProfilePic?.webviewPath;
        handleProfilePicUpdate(profilePicRef.current!);
      }
    }
  }, [photos]);

  useEffect(() => {
    const link = datos.find((d) => d.nombre === 'link')?.valor;
    const texto = datos.find((d) => d.nombre === 'texto')?.valor;
    const descripcion = datos.find((d) => d.nombre === 'descripcion')?.valor;
    if (link) {
      setLink(link);
    }
    if (texto) {
      setTexto(texto);
    }
    if (descripcion) {
      setDescripcion(descripcion);
    }
  }, [datos]);

  return (
    <IonPage className="pet-details-page">
      <NavigationBar goBack={true} uploadPetImg={true} tag={tag} />
      <IonContent fullscreen>
        <div className="pet-details-page__img-container">
          <div className="profile">
            {img_link ? (
              <img
                src={`data:image/jpeg;base64,${img_link}`}
                alt="petProfilePic"
              />
            ) : (
              <img src={placeholder} alt={nombre} />
            )}

            {isUpdating && (
              <div className="profile__spinner">
                <IonSpinner color="light" />
              </div>
            )}
          </div>
        </div>

        <div className="ion-padding-horizontal">
          <div className="pet-details-page__details ion-padding-horizontal">
            <div className="top-data">
              <div className="nombre">
                <p>{nombre}</p>
              </div>

              <IonIcon icon={heart} color="danger" />
            </div>

            <div className="data-boxes">
              <div className="box">
                <div className="title">Edad</div>
                <div className="data">{calculoEdad()}</div>
              </div>

              <div className="box">
                <div className="title">Raza</div>
                <div className="data">{raza}</div>
              </div>

              <div className="box">
                <div className="title">Sexo</div>
                <div className="data">{formattedSexo}</div>
              </div>
            </div>
          </div>

          <PetDetailsOption
            action={() =>
              setGestionSeguroPopoverState({
                showPopover: true,
                event: undefined,
              })
            }
            img={Shield}
            title={'Carnet y modificación de datos'}
            description={`Accede al carnet de asociado de ${pet?.nombre}, modifica tus datos o los de ${pet?.nombre} y más!`}
          />

          <PetDetailsOption
            action={() => goTo('siniestros/new')}
            img={Health}
            title={'Nueva enfermedad o accidente'}
            description={
              'Solicita un reintegro de gastos veterinarios por un nuevo evento.'
            }
          />

          <PetDetailsOption
            action={() => redirectToUrl(vacunasLink)}
            img={Vaccine}
            title={vacunasTexto}
            description={vacunasDescripcion}
          />

          <PetDetailsOption
            action={() => goTo('siniestros/show-siniestros')}
            img={MedicalRecord}
            title={'Historial de siniestros'}
            description={`Gestioná las enfermedades y accidentes de ${pet?.nombre} que ya están cargadas, agregá gastos, y más!`}
          />

          <PetDetailsOption
            action={() =>
              setNutricionPopoverState({ showPopover: true, event: undefined })
            }
            img={Nutrition}
            title={'Nutrición'}
            description={`Controla el peso y la alimentación de ${nombre}`}
          />
        </div>

        <GestionSeguro
          pet={pet}
          gestionSeguroPopoverState={gestionSeguroPopoverState}
          setGestionSeguroPopoverState={setGestionSeguroPopoverState}
        />

        <NutricionMenu
          pet={pet}
          mutate={mutate}
          nutricionPopoverState={nutricionPopoverState}
          setNutricionPopoverState={setNutricionPopoverState}
        />

        <ToastAlert
          isOpen={showFileSizeValidationToast}
          setShowToast={setShowFileSizeValidationToast}
          message="Ups... el tamaño maximo admitido es 8 mb."
          color="warning"
          duration={4500}
        />
      </IonContent>
    </IonPage>
  );
};

export default PetDetailsPage;
