import React from 'react';

import { IonCol, IonGrid, IonPopover, IonRow } from '@ionic/react';

import Camera from '../../assets/cute-camera.png';
import PDF from '../../assets/pdf.png';
import { useMedia } from '../../context/MediaContext';
import './FileOptionsPopover.scss';
import { FileOptionsPopoverProps } from './types';

const FileOptionsPopover: React.FC<FileOptionsPopoverProps> = ({
  popoverState,
  setShowPopover,
  tag,
  handleFileInput,
}) => {
  const { takePhoto } = useMedia();

  const fileOptions = [
    {
      label: 'Nueva imagen desde camara o galería',
      // description: 'This is the description for card 1.',
      img: Camera,
      onClick: () => takePhoto('PHOTOS', tag),
    },
    {
      label: 'Nuevo archivo PDF',
      // description: 'This is the description for card 1.',
      img: PDF,
      onClick: () => handleFileInput(),
    },
  ];

  return (
    <div className="file-options">
      <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        cssClass="file-options-popover"
        onDidDismiss={() => {
          setShowPopover({
            showPopover: false,
            event: undefined,
          });
        }}
      >
        <div
          onClick={() =>
            setShowPopover({
              showPopover: false,
              event: undefined,
            })
          }
        >
          <IonGrid>
            {fileOptions.map((element, index) => (
              <IonRow
                key={index}
                onClick={() => element.onClick()}
                className={
                  index !== fileOptions.length - 1
                    ? 'ion-margin-bottom'
                    : undefined
                }
              >
                <IonCol size="3">
                  <img src={element.img} alt={element.label} />
                </IonCol>
                <IonCol>
                  <div>
                    <div className="title">{element.label}</div>
                    {/* <div className="subtitle">{element.description}</div> */}
                  </div>
                </IonCol>
              </IonRow>
            ))}
          </IonGrid>
        </div>
      </IonPopover>
    </div>
  );
};

export default FileOptionsPopover;
