import React, { useEffect, useState } from 'react';

import { IonButton, IonContent, IonPage, useIonRouter } from '@ionic/react';
import { A11y, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import CatPlaceholder from '../../assets/placeholders/cat.svg';
import DogPlaceholder from '../../assets/placeholders/dog.svg';
import NavigationBar from '../../components/NavigationBar';
import SiniestroCard from '../../components/SiniestroCard';
import { useMascotas } from '../../context/MascotasContext';
import { useDatos } from '../../hooks/useDatos';
import { Siniestro } from '../../interfaces/Siniestro';
import './AllSiniestrosPage.scss';

const AllSiniestrosPage: React.FC = () => {
  const {
    mascotas: { pets, isValidating },
  } = useMascotas();
  const history = useIonRouter();
  const [selectedPetIds, setSelectedPetIds] = useState<number[]>([]);
  const siniestros: Siniestro[] =
    pets?.reduce((acc: Siniestro[], p) => {
      return acc.concat(p.siniestros);
    }, []) || [];
  const datos = useDatos();

  const [vacunasLink, setLink] = useState<string>(
    'https://api.whatsapp.com/send?phone=5491121808888'
  );
  const [vacunasTexto, setTexto] = useState<string>(
    'Nueva Vacuna, guardería o eutanasia'
  );

  useEffect(() => {
    const link = datos.find((d) => d.nombre === 'link')?.valor;
    const texto = datos.find((d) => d.nombre === 'texto')?.valor;
    if (link) {
      setLink(link);
    }
    if (texto) {
      setTexto(texto);
    }
  }, [datos]);

  return (
    <IonPage className="all-siniestros-page">
      <NavigationBar goBack={false} path="home" />
      <IonContent fullscreen>
        <div className="filter ion-margin-bottom ion-padding">
          <Swiper
            modules={[Pagination, Scrollbar, A11y]}
            slidesPerView={3}
            spaceBetween={15}
            pagination={{ clickable: true }}
            className="swiper"
          >
            {pets &&
              !isValidating &&
              pets.map((pet) => (
                <SwiperSlide className="swiperSlide">
                  <div
                    key={pet.id}
                    onClick={() => {
                      const index = selectedPetIds.indexOf(pet.id);
                      if (index !== -1) {
                        setSelectedPetIds([
                          ...selectedPetIds.slice(0, index),
                          ...selectedPetIds.slice(index + 1),
                        ]);
                      } else {
                        setSelectedPetIds([...selectedPetIds, pet.id]);
                      }
                    }}
                    className={`${!pet.img_link && 'filter__pet-no-img-link'} ${
                      selectedPetIds.indexOf(pet.id) !== -1 &&
                      'filter__selected-pet animate__animated animate__pulse animate__faster'
                    }`}
                  >
                    <img
                      src={
                        pet.img_link
                          ? `data:image/jpeg;base64,${pet.img_link}`
                          : pet.animal === 'Perro'
                          ? DogPlaceholder
                          : CatPlaceholder
                      }
                    />
                    <p>{pet.nombre}</p>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <div className="siniestros">
          {siniestros &&
            siniestros
              .filter(
                (s) =>
                  selectedPetIds.length === 0 ||
                  selectedPetIds.indexOf(Number(s.mascotaId)) !== -1
              )
              .map((siniestro: Siniestro, index: number) => (
                <SiniestroCard
                  key={index}
                  siniestro={siniestro}
                  pets={pets}
                  allSiniestros={true}
                  sexo={
                    pets?.find((p) => p.id.toString() === siniestro.mascotaId)
                      ?.sexo ?? 'M'
                  }
                />
              ))}
        </div>
        <div className="ion-padding">
          <IonButton
            mode="ios"
            expand="block"
            onClick={() => history.push('/tabs/siniestros/pets')}
          >
            Nuevo accidente o enfermedad
          </IonButton>
          <IonButton
            mode="ios"
            expand="block"
            fill='outline'
            target="_blank"
            href={vacunasLink}
          >
            Nueva {vacunasTexto.toLocaleLowerCase()}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AllSiniestrosPage;
