import { useState, useEffect } from 'react';
import { getDatos as axiosGetDatos } from '../axios/getDatos';
import { Datos } from '../interfaces/Datos';

export const useDatos = () => {
  const [datos, setDatos] = useState<Datos[]>([]);

  const getDatos = async () => {
    const datos = await axiosGetDatos();
    setDatos(datos);
  };

  useEffect(() => {
    getDatos();
  }, []);

  return datos;
};
