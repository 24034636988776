import React, { useEffect, useState, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';

import {
  IonButton,
  IonLabel,
  IonSpinner,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonItem,
  IonInput,
  useIonRouter,
} from '@ionic/react';

import {
  validationName,
  validationPhone,
} from '../../assets/validations/validationRegex';
import { useMascotas } from '../../context/MascotasContext';
import { useSubmitNewFood } from '../../hooks';
import { useAddPetFood } from '../../hooks/useAddPetFood';
import { useEnter } from '../../hooks/useEnter';
import { useFoodBags } from '../../hooks/useFoodBags';
import { FoodBagRes, FoodReq } from '../../interfaces/Food';
import { Animal } from '../../interfaces/Pet';
import { ConsumoRes } from '../../interfaces/consumo';
import { SetLoadingContext } from '../../pages/tabs';
import { today } from '../../utils/DateUtilities';
import AddFoodPetItem from '../AddFoodPetItem';
import AdviceAlert from '../AdviceAlert';
import FormItem from '../FormItem';
import Searchbar from '../Searchbar';
import ToastAlert from '../ToastAlert';
import './AddFoodForm.scss';
import { AddFoodFormProps, AddFoodFormInputs } from './types';

const AddFoodForm: React.FC<AddFoodFormProps> = () => {
  const { control, errors, handleSubmit, setError } =
    useForm<AddFoodFormInputs>();

  const history = useIonRouter();
  const { ref: enterRef, handleEnter } = useEnter();
  const {
    mascotas: { pets, isValidating: mascotasValidating },
  } = useMascotas();
  const { submitNewFood, isSubmitting, isSubmitted, setSubmitted } =
    useSubmitNewFood();

  const { added, addToList, removeFromList } = useAddPetFood();
  const {
    foodBags,
    marcasList,
    foodListFormat,
    isValidating: foodBagsValidating,
  } = useFoodBags();
  const bottom = useRef<any>(null);

  const [isSameRaza, setIsSameRaza] = useState<boolean>(false);
  const [foodList, setFoodList] = useState(foodListFormat);
  const [consumoState, setConsumoState] = useState<ConsumoRes>(
    pets?.map((pet) => {
      return { mascota: pet, consumo: 0 };
    }) as ConsumoRes
  );
  const [selectedFood, setSelectedFood] = useState<FoodBagRes | undefined>(
    undefined
  );
  const [sameRazaAlertOpen, setSameRazaAlertOpen] = useState<boolean>(false);
  const [noAlimentoAlertOpen, setNoAlimentoAlertOpen] =
    useState<boolean>(false);
  const setIsLoading = useContext(SetLoadingContext);
  useEffect(() => {
    setIsLoading(isSubmitting || mascotasValidating || foodBagsValidating);
  }, [isSubmitting, mascotasValidating, foodBagsValidating]);

  const filterFood = (marca?: { value: string; label: string }) => {
    control.setValue('alimento', undefined);
    let animal: Animal | undefined = undefined;
    if (added.length > 0) {
      animal = pets && pets.find((p) => p.id === added[0].mascotaId)!.animal;
    }

    let foodByMarca: FoodBagRes[] = [];
    let foodByAnimal: FoodBagRes[] = [];

    if (marca && marca.value) {
      foodByMarca = foodBags
        ? foodBags.filter((food) => food.marca === marca.value)
        : [];
    } else {
      foodByMarca = foodBags ?? [];
    }

    if (animal) {
      foodByAnimal = foodByMarca.filter((food) => food.animal === animal);
    } else {
      foodByAnimal = foodByMarca;
    }

    setFoodList(
      foodByAnimal.map((e) => ({
        value: e.id,
        label: e.nombre,
      }))
    );
  };

  const filterPets = () => {
    if (control.getValues('alimento')) {
      const found = foodBags?.find(
        (food) => food.id === control.getValues('alimento').value
      );
      setSelectedFood(found);
      setConsumoState(
        consumoState.filter(
          (par) => par.mascota.animal === found?.animal
        ) as ConsumoRes
      );
    } else {
      setConsumoState(
        pets?.map((pet) => {
          return { mascota: pet, consumo: 0 };
        }) as ConsumoRes
      );
    }
  };

  const scrollToBottom = () => {
    bottom.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const modalidadCompraOptions = ['WhatsApp', 'Web', 'Telefono', 'Otros'];

  const onSubmit = async ({
    alimento,
    kilos,
    duracion,
    fechaCompra,
    contactoProveedor,
    nombrePetShop,
    modalidadCompra,
  }: AddFoodFormInputs) => {
    const reqBody: FoodReq = {
      mascotas: added.map((a) => a.mascotaId),
      alimentoId: alimento.value as number,
      //PRESENTACION
      kilos: Number(kilos),
      duracion: Number(duracion),
      fechaCompra: fechaCompra,
      contactoProveedor: contactoProveedor,
      nombrePetShop: nombrePetShop,
      modalidadCompra: modalidadCompra,
    };
    const response = await submitNewFood(reqBody);
    if (response === 409) {
      setError('alimento', {
        type: 'validate',
        message:
          'La bolsa seleccionada ya fue cargada. Por favor, ingrese otro alimento.',
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [addToList]);

  useEffect(() => {
    if (added.length > 1 && !isSameRaza) {
      setSameRazaAlertOpen(true);
    } else if (added.length >= 1 && !foodList?.length) {
      setNoAlimentoAlertOpen(true);
    }
  }, [(added.length > 1 && !isSameRaza) || !foodList?.length]);

  useEffect(() => {
    filterPets();
  }, [pets, foodList]);

  useEffect(() => {
    control.setValue('marca', undefined);
    filterFood();

    function getRazaByPetId(petId) {
      const pet = pets?.find((pet) => pet.id === petId);
      return pet ? pet.animal : null;
    }

    function areAllPetsOfSameRaza(addedPets) {
      if (addedPets.length === 0) {
        // No hay mascotas para comparar
        return false;
      }

      const firstPetRaza = getRazaByPetId(addedPets[0].mascotaId);

      for (const addedPet of addedPets) {
        const petRaza = getRazaByPetId(addedPet.mascotaId);
        if (petRaza !== firstPetRaza) {
          // Las razas no coinciden
          return false;
        }
      }
      // Todas las mascotas tienen la misma raza
      return true;
    }

    setIsSameRaza(areAllPetsOfSameRaza(added));
  }, [added]);

  return (
    <>
      <form
        className="add-food-form ion-padding"
        onSubmit={handleSubmit(onSubmit)}
        onKeyUp={handleEnter}
      >
        <div
          hidden={consumoState?.length === 0}
          className="add-food-form__subtitle ion-margin-bottom"
        >
          ¿Quiénes comerán de este alimento?
        </div>

        {consumoState?.map((p) => (
          <AddFoodPetItem
            pet={p.mascota}
            addToList={addToList}
            removeFromList={removeFromList}
            added={added}
            consumoEstimado={0}
            initialStateAdded={false}
            canUpdate={false}
            key={p.mascota.id}
          />
        ))}

        {added.length !== 0 && isSameRaza && (
          <div className="ion-padding-top">
            <FormItem
              errors={errors}
              render={({ value, onChange }) => (
                <>
                  <IonLabel className="add-food-form__label ion-margin-bottom">
                    Marca
                  </IonLabel>
                  <Searchbar
                    placeholder="Elija una opción"
                    value={value}
                    options={marcasList ?? []}
                    onChange={(e) => {
                      onChange(e);
                      filterFood(e);
                    }}
                  />
                </>
              )}
              control={control}
              name="marca"
              rules={{
                required: {
                  value: true,
                  message: 'Por favor, indique la marca del alimento',
                },
              }}
            />

            <div
              hidden={!foodList?.length}
              className={
                !control.getValues('marca')
                  ? 'add-food-form__disabled'
                  : undefined
              }
            >
              <FormItem
                errors={errors}
                render={({ value, onChange }) => (
                  <>
                    <IonLabel className="add-food-form__label ion-margin-vertical">
                      Alimento
                    </IonLabel>
                    <Searchbar
                      placeholder="Elija una opción"
                      value={value}
                      options={foodList ?? []}
                      onChange={(e) => {
                        onChange(e);
                        filterPets();
                      }}
                    />
                  </>
                )}
                control={control}
                name="alimento"
                rules={{
                  required: {
                    value: true,
                    message: 'Por favor, indique un tipo de alimento',
                  },
                }}
              />
            </div>

            {/* NUEVO FORM */}
            {control.getValues('alimento') && consumoState?.length !== 0 && (
              <div className="add-food-form__food-info ion-padding-horizontal ion-padding-bottom ion-margin-top">
                <FormItem
                  render={({ onChange, name, value }) => (
                    <IonItem mode="ios" className="ion-no-padding">
                      <IonLabel position="floating">
                        ¿Cuando compro la bolsa?
                      </IonLabel>
                      <IonDatetime
                        name={name}
                        value={value}
                        displayFormat="DD/MM/YYYY"
                        cancelText="Cancelar"
                        doneText="Ok"
                        onIonChange={onChange}
                        max={today}
                      />
                    </IonItem>
                  )}
                  control={control}
                  name="fechaCompra"
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: 'Por favor, ingrese la fecha de compra.',
                    },
                  }}
                />

                <FormItem
                  name="duracion"
                  control={control}
                  errors={errors}
                  render={({ onChange, name, value }) => (
                    <>
                      <IonItem lines="inset" className="ion-no-padding">
                        <IonLabel position="floating">
                          ¿Cuantos días dura la bolsa?
                        </IonLabel>
                        <IonInput
                          type="number"
                          name={name}
                          value={value}
                          onIonChange={(e) => {
                            onChange(e);
                          }}
                        />
                      </IonItem>
                    </>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Por favor, complete este campo.',
                    },
                  }}
                />

                <FormItem
                  name="nombrePetShop"
                  control={control}
                  errors={errors}
                  render={({ onChange, name, value }) => (
                    <>
                      <IonItem lines="inset" className="ion-no-padding">
                        <IonLabel position="floating">
                          Nombre de su Pet Shop
                        </IonLabel>
                        <IonInput
                          type="text"
                          name={name}
                          value={value}
                          onIonChange={(e) => {
                            onChange(e);
                          }}
                        />
                      </IonItem>
                    </>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Por favor, complete este campo.',
                    },
                    pattern: validationName,
                  }}
                />

                <FormItem
                  name="contactoProveedor"
                  control={control}
                  errors={errors}
                  render={({ onChange, name, value }) => (
                    <>
                      <IonItem lines="inset" className="ion-no-padding">
                        <IonLabel position="floating">
                          Teléfono de su Pet Shop
                        </IonLabel>
                        <IonInput
                          type="number"
                          name={name}
                          value={value}
                          onIonChange={(e) => {
                            onChange(e);
                          }}
                        />
                      </IonItem>
                    </>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Por favor, complete este campo.',
                    },
                    pattern: validationPhone,
                  }}
                />

                <FormItem
                  name="kilos"
                  control={control}
                  errors={errors}
                  render={({ onChange, name, value }) => (
                    <>
                      <IonLabel className="add-food-form__label ion-margin-vertical">
                        ¿Cuántos Kg contiene la bolsa?
                      </IonLabel>
                      <IonSelect
                        name={name}
                        value={value}
                        onIonChange={onChange}
                        placeholder="Seleccione la cantidad"
                        interface="popover"
                        className="ion-no-padding"
                      >
                        {selectedFood?.presentaciones.map((e, index) => (
                          <IonSelectOption key={index} value={e}>
                            {`${e} Kg`}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Por favor, complete este campo.',
                    },
                  }}
                />

                <FormItem
                  name="modalidadCompra"
                  control={control}
                  errors={errors}
                  render={({ onChange, name, value }) => (
                    <>
                      <IonLabel className="add-food-form__label ion-margin-vertical">
                        ¿Como compras el alimento de tu mascota?
                      </IonLabel>
                      <IonSelect
                        name={name}
                        value={value}
                        onIonChange={onChange}
                        placeholder="Seleccione una modalidad"
                        interface="popover"
                        className="ion-no-padding"
                      >
                        {modalidadCompraOptions.map((element, index) => (
                          <IonSelectOption key={index} value={element}>
                            {element}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'Por favor, complete este campo.',
                    },
                  }}
                />
              </div>
            )}
            {/* END NUEVO FORM */}

            <div className="ion-margin-top" ref={bottom}>
              <IonButton
                type="submit"
                ref={enterRef}
                mode="ios"
                expand="block"
                disabled={consumoState?.length === 0}
              >
                {isSubmitting && (
                  <IonSpinner
                    name="crescent"
                    className="button-spinner"
                    slot="end"
                  />
                )}
                Confirmar
              </IonButton>
            </div>
          </div>
        )}

        <AdviceAlert
          isOpen={isSubmitted}
          setOpen={setSubmitted}
          header={isSubmitted ? ' ¡ Éxito ! ' : 'Error'}
          subHeader=""
          message={
            isSubmitted
              ? 'El alimento se cargo correctamente.'
              : 'El servidor se encuentra en mantenimiento. Intente nuevamente más tarde.'
          }
          action={() => {
            if (isSubmitted) {
              history.back();
            }
          }}
        />
      </form>

      <ToastAlert
        isOpen={sameRazaAlertOpen}
        setShowToast={setSameRazaAlertOpen}
        message="Ups... Perros y gatos no pueden comer del mismo alimento."
        color="warning"
        duration={3500}
      />

      <ToastAlert
        isOpen={noAlimentoAlertOpen}
        setShowToast={setNoAlimentoAlertOpen}
        message="Ups... La marca que selecciono no posee alimentos para su mascota."
        color="warning"
        duration={3500}
      />
    </>
  );
};

export default AddFoodForm;
