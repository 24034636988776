import { useState } from 'react';

import { mutate } from 'swr';

import { PETS } from '../axios/constants';
import { updateProfilePic as axiosUpdateProfilePic } from '../axios/updateProfilePic';

export const useProfilePic = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const updateProfilePic = async (webviewPath: string, mascotaId: string) => {
    setIsUpdating(true);

    if (!webviewPath) {
      setIsUpdating(false);
      return;
    }

    try {
      // Convertir el blob webviewPath a Data URL
      const pic = await convertBlobToDataURL(webviewPath);

      if (typeof pic === 'string') {
        await axiosUpdateProfilePic({ mascotaId, pic });
        mutate(PETS);
      } else {
        console.error('La conversión a Data URL falló.');
      }
    } catch (error) {
      console.error('Error al actualizar la foto de perfil:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  // Función para convertir blob a Data URL
  const convertBlobToDataURL = async (blobUrl: string) => {
    const response = await fetch(blobUrl, { mode: 'no-cors' });
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  return { updateProfilePic, isUpdating };
};
