import React from 'react';
import Collapsible from 'react-collapsible';

import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import moment from 'moment';

import Checked from '../../assets/checked.png';
import { useSiniestro } from '../../context/SiniestroContext';
import { useDatosConsulta } from '../../hooks/useDatosConsulta';
import { DatosConsulta } from '../../interfaces/DatosConsulta';
import { SubVetForm } from '../../interfaces/SubVetForm';
import { capitalizeFirstLetter } from '../../utils/General';
import VetFormComprobanteInput from '../VetFormComprobanteInput';
import './VetFormCompletado.scss';
import { VetFormCompletadoProps } from './types';

const VetFormCompletado: React.FC<VetFormCompletadoProps> = ({ vetForm }) => {
  const svfs: SubVetForm[] = [
    vetForm.consulta,
    ...vetForm.cirugiasMascota,
    ...vetForm.estudiosMascota,
    ...vetForm.farmacologiaMascota,
    ...vetForm.internacionesMascota,
    ...vetForm.practicasMascota,
  ].filter((v) => v);

  const { generateSummary } = useDatosConsulta(svfs);
  const {
    sintomas: { data: sintomas },
  } = useSiniestro();

  const relevantSintomas = sintomas?.filter((s) => s.vetFormId === vetForm.id);

  const datosConsulta = svfs
    ? (svfs.find((i) => i.tipo == 'consulta') as unknown as DatosConsulta)
    : undefined;

  const { nombre, apellido } = vetForm.veterinario;

  return (
    <div className="vet-form-completado">
      <Collapsible
        transitionTime={200}
        trigger={
          <IonGrid>
            <IonRow>
              <IonCol size="3" className="vet-form-completado__state-icon">
                {/* <IonIcon icon={checkmarkCircle} color="primary" /> */}
                <img src={Checked} alt="" />
              </IonCol>
              <IonCol>
                <>
                  <div className="vet-form-completado__type">
                    Formulario completado{' '}
                    <span>
                      por {nombre} {apellido}
                    </span>
                  </div>
                  {/* {datosConsulta && datosConsulta.modalidad &&
                    <div className="vet-form-completado__type">
                      Atención en {datosConsulta.modalidad}
                    </div>
                  } */}
                  <div className="vet-form-completado__date">
                    {moment(vetForm.updatedAt).format('DD/MM/YYYY')}
                  </div>
                </>
              </IonCol>
            </IonRow>
            <IonRow
              // Detiene la propagación del evento de clic
              onClick={(e) => e.stopPropagation()}
            >
              <IonCol offset="3">
                <VetFormComprobanteInput vetForm={vetForm} />
              </IonCol>
            </IonRow>
          </IonGrid>
        }
      >
        <>
          <IonGrid className="vet-form-completado__top-grid ion-no-padding ion-padding-horizontal ion-margin">
            <IonRow>
              {datosConsulta &&
                datosConsulta.descripcionInicial !== undefined && (
                  <>
                    <IonCol className="title">Diagnostico presuntivo: </IonCol>
                    <IonCol className="value ion-text-end">
                      {capitalizeFirstLetter(datosConsulta.descripcionInicial)}
                    </IonCol>
                  </>
                )}
            </IonRow>
          </IonGrid>

          <IonGrid className="vet-form-completado__top-grid ion-no-padding ion-padding-horizontal ion-margin-horizontal">
            <IonRow>
              {relevantSintomas &&
                relevantSintomas.map((e, index) => {
                  if (e.sintoma !== '') {
                    return (
                      <>
                        <IonCol className="title">Síntomas: </IonCol>
                        <IonCol className="ion-text-end">{e.sintoma}</IonCol>
                      </>
                    );
                  }
                })}
            </IonRow>
          </IonGrid>

          <div className="vet-form-completado__bottom-container ion-padding-horizontal ion-padding-bottom">
            {generateSummary()}
          </div>
        </>
      </Collapsible>
    </div>
  );
};

export default VetFormCompletado;
