import React, { useRef, useState } from 'react';

import { IonSegment, IonSegmentButton, IonLabel, IonAlert } from '@ionic/react';

import { useMedia } from '../../context/MediaContext';
import { useSiniestro } from '../../context/SiniestroContext';
import FileUploader from '../FileUploader';
import './FormAddDocObligatoria.scss';
import { FormAddDocObligatoriaProps } from './types';

const FormAddDocObligatoria: React.FC<FormAddDocObligatoriaProps> = () => {
  const [CBU_Type, setCBU_Type] = useState<string>('propio');
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const {
    documentacion: { data: documentacion },
  } = useSiniestro();

  const { elementExistInMedia } = useMedia();

  const bottomRef = useRef<any>();

  const requiredMedia = [
    { type: 'docPersonal', detail: 'dni_frente', placeholder: 'DNI frente' },
    { type: 'docPersonal', detail: 'dni_dorso', placeholder: 'DNI dorso' },
    {
      type: 'docPersonal',
      detail: 'libreta_vacunacion',
      placeholder: 'Libreta de vacunación',
    },
  ];

  return (
    <div className="form-add-doc-obligatoria ion-padding-start">
      {documentacion && (
        <>
          {requiredMedia.map((element, index) => (
            <FileUploader
              key={index}
              tag={{ type: element.type, detail: element.detail }}
              placeholder={element.placeholder}
              hasData={elementExistInMedia(element.detail)}
            />
          ))}

          <div className="ion-margin-top">
            <span className="form-add-doc-obligatoria__comprobanteCBU">
              Comprobante de CBU
            </span>
            <IonSegment
              className="ion-no-margin"
              value={CBU_Type}
              mode="md"
              onIonChange={(e) => setCBU_Type(e.detail.value!)}
            >
              <IonSegmentButton value="propio">
                <IonLabel> Cuenta propia</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                value="tercero"
                onClick={(e) => {
                  bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
                  e.persist();
                  setShowAlert((prevState) => !prevState);
                }}
              >
                <IonLabel>Cuenta de tercero</IonLabel>
              </IonSegmentButton>
            </IonSegment>

            {CBU_Type === 'propio' ? (
              <FileUploader
                tag={{ type: 'docPersonal', detail: 'cbu' }}
                placeholder="CBU"
                hasData={elementExistInMedia('cbu')}
              />
            ) : (
              <>
                <FileUploader
                  tag={{ type: 'docPersonal', detail: 'tercero_cbu' }}
                  placeholder="CBU de tercero"
                  hasData={elementExistInMedia('tercero_cbu')}
                />
                <FileUploader
                  tag={{ type: 'docPersonal', detail: 'constancia' }}
                  placeholder="Libreta de vacunación"
                  hasData={elementExistInMedia('constancia')}
                />
              </>
            )}
            <div ref={bottomRef} />

            {/* <FileUploader
              tag={{ type: 'docPersonal', detail: 'doc_extra' }}
              placeholder="Documentación Extra"
            /> */}

            <IonAlert
              mode="ios"
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              header="Cuenta de tercero"
              subHeader="Mensaje importante"
              message="El titular de la póliza debe firmar una nota autorizando al tercero (nombre, apellido y CUIL/DNI) a recibir el reintegro."
              buttons={['Entendido']}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FormAddDocObligatoria;
