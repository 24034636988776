import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Tour, { ReactourStep, ReactourStepPosition } from 'reactour';
import { useHistory } from 'react-router';

import { TutorialContext } from '../../context/TutorialContext';
import { TutorialBasicProps } from './types';
import Step from '../Step';
import HavePetAlert from '../HavePetAlert';

import './TutorialBasic.scss';

const TutorialBasic: React.FC<TutorialBasicProps> = () => {
  const history = useHistory();

  const { tutorialDoneStatus, setTutorialDoneStatus } = useContext(
    TutorialContext
  );
  
  const [steps, setSteps] = useState<ReactourStep[]>([]);

  const [startTour, setStartTour] = useState(false);

  const [goToStep, setGoToStep] = useState(0);

  const pushIfNotIn = useCallback(
    (path: string) => {
      if (
        history.location.pathname.startsWith(path) &&
        !history.location.pathname.endsWith(path)
      ) {
        history.push(path);
      }
    },
    [history]
  );

  const homeSteps: ReactourStep[] = useMemo(
    () => [
      {
        content: (
          <>
            <h2>Bienvenido/a a </h2>
            <img style={{ width: '200px' }} src="assets/img/FielPetLogo.png" />
            <p>Comencemos con el tutorial</p>
          </>
        ),
        position: 'center' as ReactourStepPosition,
      },
      {
        selector: '[data-tut="reactour__add_pet_button"]',
        content: (
          <Step
            title="Este es el botón para asegurar una nueva mascota"
            body="Una vez asegurada, completando los formularios correspondientes, podrá acceder al perfil de la misma."
          />
        ),
        stepInteraction: false,
      },
      // {
      //   selector: '[data-tut="vet-call"]',
      //   content: (
      //     <Step
      //       title="Este es el botón de videollamada"
      //       body="Aquí podrá solicitar un turno para realizar una videollamada con un veterinario"
      //       hint="El botón estara habilitado una vez que asegure su primer mascota"
      //     />
      //   ),
      //   stepInteraction: false,
      // },
      {
        selector: '[data-tut="reactour__home-modal"]',
        content: (
          <Step
            title="Este es el botón para desplegar el menú de inicio"
            body="Aquí podrá gestionar accidentes, reintegros y eventos de su mascota."
          />
        ),
        stepInteraction: false,
      },
      {
        selector: '[data-tut="reactour__home"]',
        content: <Step body="Aquí es donde se encuentra actualmente..." />,
      },
      {
        selector: '[data-tut="reactour__alimentacion"]',
        content: <Step body="Presione en Alimentación" />,
      },
    ],
    []
  );

  const historialSteps: ReactourStep[] = useMemo(
    () => [
      {
        content: (
          <Step
            title="Bienvenido al Calendario!"
            body="Aquí encontrará los eventos de sus mascotas."
            callback={() => pushIfNotIn('/tabs/historial')}
          />
        ),
        position: 'center' as ReactourStepPosition,
      },
      {
        selector: '[data-tut="reactour__historial-segments"]',
        content: (
          <Step body="Cada pestaña muestra los eventos según su estado actual." />
        ),
      },
      {
        selector: '[data-tut="reactour__historial-segments--hechos"]',
        content: <Step body="Eventos que fueron realizados." />,
      },
      {
        selector: '[data-tut="reactour__historial-segments--atrasados"]',
        content: (
          <Step body="Eventos que no se marcaron como realizados y expiró su fecha de realización." />
        ),
      },
      {
        selector: '[data-tut="reactour__historial-segments--proximos"]',
        content: <Step body="Eventos que están por venir." />,
      },
      {
        selector: '[data-tut="reactour__add-event"]',
        content: (
          <Step
            title="Este es el botón para agregar un nuevo evento"
            body="Una vez cargado será mostrado en alguna de las tres pestañas,
            en la cual podrá acceder al mismo para editar su información, 
            en caso de ser necesario. Si el evento no ocurrió y esta próximo a cumplirse sera avisado/a con una notificación."
            hint="El botón estará habilitado una vez que asegure su primer mascota."
          />
        ),
        stepInteraction: false,
      },
      {
        selector: '[data-tut="reactour__alimentacion"]',
        content: <Step body="Presione en Alimentación" />,
      },
    ],
    [pushIfNotIn]
  );
  const alimentacionSteps: ReactourStep[] = useMemo(
    () => [
      {
        content: (
          <Step
            title="Bienvenido a Alimentación!"
            body="Aquí encontrará las bolsas de comida que tiene actualmente."
            callback={() => pushIfNotIn('/tabs/alimentacion')}
          />
        ),
        position: 'center' as ReactourStepPosition,
      },
      {
        selector: '[data-tut="reactour__add-food"]',
        content: (
          <Step
            title="Este es el botón para agregar un nuevo alimento."
            body="El alimento cargado se irá consumiendo en base a lo que comen
                las mascotas que vinculó al mismo, cuando se aproxime a su fin
                será avisado con una notificación. Puede rellenarlo o agregar uno nuevo cuantas veces quiera."
            hint="El botón estara habilitado una vez que asegure su primer mascota."
          />
        ),
        stepInteraction: false,
      },
      {
        selector: '[data-tut="reactour__add-settings"]',
        content: <Step body="Presione en Ajustes" />,
      },
    ],
    [pushIfNotIn]
  );

  const ajustesSteps: ReactourStep[] = useMemo(
    () => [
      {
        content: (
          <Step
            title="Bienvenido a Ajustes!"
            body="Aquí encontrara las funcionalidades propias de la cuenta."
            callback={() => pushIfNotIn('/tabs/ajustes')}
          />
        ),
        position: 'center' as ReactourStepPosition,
      },
      {
        selector: '[data-tut="reactour__sincronizar-mascotas"]',
        content: (
          <Step body="Si usted ha cargado mascotas desde la web de Fielpet, puede sincronizarlas para verlas en la aplicación presionando aquí." />
        ),
        stepInteraction: false,
      },
      {
        selector: '[data-tut="reactour__ver-tutorial"]',
        content: <Step body="Ver el tutorial cuantas veces quiera." />,
      },
      {
        selector: '[data-tut="reactour__cerrar-sesion"]',
        content: (
          <Step body="O cerrar la sesion, en caso de querer logearse con una cuenta diferente." />
        ),
      },
      {
        content: (
          <Step
            title="Felicidades ha completado el tour!"
            body="Disfrute de la app."
          />
        ),
        position: 'center' as ReactourStepPosition,
      },
    ],
    [pushIfNotIn]
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Comparing with false because tutorialDoneStatus is undefined until its value is getted from the storage
    if (tutorialDoneStatus === false) {
      setTimeout(() => {
        history.push('/tabs/home');
        setStartTour(true);
      }, 500);
    }
  }, [tutorialDoneStatus, setStartTour, history]);

  useEffect(() => {
    if (tutorialDoneStatus === false) {
      if (history.location.pathname.startsWith('/tabs/home')) {
        setSteps(homeSteps);
      }
      if (history.location.pathname.startsWith('/tabs/historial')) {
        setSteps(historialSteps);
        setGoToStep(0);
      }
      if (history.location.pathname.startsWith('/tabs/alimentacion')) {
        setSteps(alimentacionSteps);
        setGoToStep(0);
      }
      if (history.location.pathname.startsWith('/tabs/ajustes')) {
        setSteps(ajustesSteps);
        setGoToStep(0);
      }
    }
  }, [
    tutorialDoneStatus,
    history.location.pathname,
    setSteps,
    setGoToStep,
    homeSteps,
    historialSteps,
    alimentacionSteps,
    ajustesSteps,
  ]);

  return (
    <>
      <Tour
        update={history.location.pathname}
        onBeforeClose={() => {
          setTutorialDoneStatus(true);
          setOpen(true);
        }}
        closeWithMask={false}
        steps={steps}
        startAt={0}
        goToStep={goToStep}
        isOpen={startTour}
        onRequestClose={() => setStartTour(false)}
        rounded={5}
        accentColor='#1ea473'
      />
      <HavePetAlert open={open} setOpen={setOpen} />
    </>
  );
};

export default TutorialBasic;
