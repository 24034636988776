import React, { useState, useContext, useEffect } from 'react';

import { RefresherEventDetail } from '@ionic/core';
import {
  IonPage,
  IonContent,
  IonButton,
  IonToast,
  IonCol,
  IonGrid,
  IonRow,
  useIonRouter,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';

import PetFood from '../../assets/pet-food.png';
import DeleteAlert from '../../components/DeleteAlert';
import FoodInfoItem from '../../components/FoodInfoItem';
import NavigationBar from '../../components/NavigationBar';
import PageRefresher from '../../components/PageRefresher';
import { useMascotas } from '../../context/MascotasContext';
import { useNutrition, useSubmitNewFood } from '../../hooks';
import { SetLoadingContext } from '../tabs';
import './NutritionPage.scss';

const NutritionPage: React.FC = () => {
  const { deleteFood, isSubmitting } = useSubmitNewFood();

  const [openDeleteAlert, setOpenDeleteAlert] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<undefined | number>(undefined);
  const [showToast, setShowToast] = useState<boolean>(false);

  const history = useIonRouter();
  const { foods, mutate, isValidating: isValidatingFood } = useNutrition();
  const {
    mascotas: { pets, isValidating: isValidatingPets },
  } = useMascotas();
  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await mutate();
    event.detail.complete();
  };

  const delFoodBag = async () => {
    if (idToDelete) {
      const rta = await deleteFood(idToDelete);
      if (!rta) {
        setShowToast(true);
      }
    }
  };
  const setIsLoading = useContext(SetLoadingContext);

  useEffect(() => {
    setIsLoading(isValidatingFood || isValidatingPets || isSubmitting);
  }, [isValidatingFood, isValidatingPets, isSubmitting]);

  return (
    <IonPage className="nutrition-page">
      <NavigationBar path="home" />
      <IonContent fullscreen>
        <PageRefresher doRefresh={doRefresh} />
        {foods?.length === 0 ? (
          <>
            <IonGrid className="nutrition-page__noFood ion-no-padding">
              <IonRow>
                <IonCol>
                  <img src={PetFood} />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <p>No hay alimentos cargados</p>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        ) : (
          foods?.map((food) => (
            <FoodInfoItem
              key={food.id}
              pets={pets}
              food={food}
              openDelAlert={setOpenDeleteAlert}
              setId={setIdToDelete}
              isDeleting={isSubmitting}
              idDeleting={idToDelete}
              mutateFood={mutate}
            />
          ))
        )}

        <div className="divButton ion-margin-top">
          <IonButton
            data-tut="reactour__add-food"
            mode="ios"
            disabled={!pets?.length}
            onClick={() =>
              history.push('/tabs/alimentacion/agregar', 'forward', 'push')
            }
          >
            Agregar nuevo alimento
          </IonButton>
        </div>

        <div>
          <DeleteAlert
            delText={' la bolsa de alimento'}
            isOpen={openDeleteAlert}
            setOpen={setOpenDeleteAlert}
            action={delFoodBag}
          />
        </div>

        <div>
          <IonToast
            position="bottom"
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={'Ups! Ocurrió un error. Intente más tarde.'}
            cssClass={'nutrition-page__toast'}
            buttons={[
              {
                side: 'start',
                icon: closeOutline,
              },
            ]}
            duration={1000}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NutritionPage;
