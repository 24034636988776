import { Camera, CameraResultType } from '@capacitor/camera';

export const useCamera = () => {
  const takePicture = async (sourceType) => {
    const image = await Camera.getPhoto({
      quality: 80,
      resultType: CameraResultType.DataUrl,
      source: sourceType,
      // direction: CameraDirection.Rear
    });

    return image.dataUrl;
  };

  return { takePicture };
};
