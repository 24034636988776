import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';

import { Directory, Filesystem } from '@capacitor/filesystem';

import { usePDF } from '../hooks/usePDF';
import { usePhoto } from '../hooks/usePhoto';
import { PDFItem } from '../interfaces/PDFItem';
import { PhotoItem } from '../interfaces/PhotoItem';

interface MediaContextType {
  takePhoto: (
    source: string,
    tag: {
      type: string;
      detail: string;
    }
  ) => Promise<void>;
  deletePhoto: (photoId: string) => void;
  clearPhotos: () => void;
  photos: PhotoItem[];
  setPhotos: React.Dispatch<React.SetStateAction<PhotoItem[]>>;
  PDFs: PDFItem[];
  handlePDF: (
    event: React.ChangeEvent<HTMLInputElement>,
    tag: {
      type: string;
      detail: string;
    },
    setShowToast: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  deletePDF: (fileName: string) => Promise<void>;
  clearPDFs: () => Promise<void>;
  tempFile: PhotoItem | PDFItem | undefined;
  setTempFile: React.Dispatch<
    React.SetStateAction<PhotoItem | PDFItem | undefined>
  >;
  media: PhotoItem[];
  confirmFile: boolean;
  setConfirmFile: React.Dispatch<React.SetStateAction<boolean>>;
  fileToBeViewed: string | undefined;
  setFileToBeViewed: React.Dispatch<React.SetStateAction<string | undefined>>;
  elementExistInMedia: (detail: string) => boolean;
  deleteCurrentMediaFile: (fileName: string) => Promise<void>;
  showMediaState: ShowMediaState | undefined;
  setShowMediaState: React.Dispatch<
    React.SetStateAction<ShowMediaState | undefined>
  >;
  disablePdfUpload: boolean;
  setDisablePdfUpload: React.Dispatch<React.SetStateAction<boolean>>;
  uploadingComprobantePago: boolean;
  setUploadingComprobantePago: React.Dispatch<React.SetStateAction<boolean>>;
  showDuplicatedInMediaToast: boolean;
  setShowDuplicatedInMediaToast: React.Dispatch<React.SetStateAction<boolean>>;
  showFileSizeValidationToast: boolean;
  setShowFileSizeValidationToast: React.Dispatch<React.SetStateAction<boolean>>;
  showSuccessfulComprobanteToast: boolean;
  setShowSuccessfulComprobanteToast: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  comprobanteMedia: Comprobante[] | undefined;
  setComprobanteMedia: React.Dispatch<
    React.SetStateAction<Comprobante[] | undefined>
  >;
}

const MediaContext = createContext<MediaContextType | undefined>(undefined);

type MediaProviderProps = {
  children: ReactNode;
};

type ShowMediaState = 'tempFile' | 'viewFile' | 'gallery';

interface Comprobante {
  concepto: string;
  file: string;
  monto: string;
}

const MediaProvider: React.FC<MediaProviderProps> = ({ children }) => {
  const [photos, setPhotos] = useState<PhotoItem[]>([]);
  const [PDFs, setPDFs] = useState<PDFItem[]>([]);
  const media = [...photos, ...PDFs];
  const [tempFile, setTempFile] = useState<PhotoItem | PDFItem>();
  const [fileToBeViewed, setFileToBeViewed] = useState<string>();
  const [confirmFile, setConfirmFile] = useState<boolean>(false);
  const [showMediaState, setShowMediaState] = useState<
    ShowMediaState | undefined
  >();
  const [disablePdfUpload, setDisablePdfUpload] = useState<boolean>(false);
  const [uploadingComprobantePago, setUploadingComprobantePago] =
    useState<boolean>(false);
  const [showDuplicatedInMediaToast, setShowDuplicatedInMediaToast] =
    useState<boolean>(false);
  const [showFileSizeValidationToast, setShowFileSizeValidationToast] =
    useState<boolean>(false);
  const [showSuccessfulComprobanteToast, setShowSuccessfulComprobanteToast] =
    useState<boolean>(false);
  const [comprobanteMedia, setComprobanteMedia] = useState<Comprobante[]>();

  const { takePhoto, deletePhoto, clearPhotos } = usePhoto({
    photos,
    setPhotos,
    setTempFile,
    setShowFileSizeValidationToast
  });

  const { handlePDF, deletePDF, clearPDFs } = usePDF({
    PDFs,
    setPDFs,
    setTempFile,
  });

  const deleteCurrentMediaFile = async (fileName: string) => {
    if (!fileName) {
      return;
    }

    const hasJpegFile: boolean = fileName.endsWith('.jpeg');

    hasJpegFile ? deletePhoto(fileName) : deletePDF(fileName);

    await Filesystem.deleteFile({
      path: fileName,
      directory: Directory.Data,
    });
  };

  const elementExistInMedia = (detail: string): boolean => {
    return media.some((element) => element.tag.detail === detail);
  };

  useEffect(() => {
    if (tempFile?.fileName.includes('petProfilePic')) {
      setConfirmFile(true);
    }

    // Sale si no hay archivo temporal o confirmación
    if (!tempFile || !confirmFile) return;

    const isComprobante = tempFile.fileName.includes('comprobante');
    if (isComprobante) setUploadingComprobantePago(true);

    // Busca si el archivo ya existe en photos o PDFs
    const existingIndex = media.findIndex(
      (file) => file.tag.detail === tempFile.tag.detail
    );

    if (existingIndex !== -1) {
      // Define la actualización para fotos o PDFs
      const updateList = (list, file) => {
        const index = list.findIndex(
          (item) => item.tag.detail === file.tag.detail
        );
        if (index !== -1) {
          const updatedList = [...list];
          updatedList[index] = file;
          return updatedList;
        }
        return [...list, file];
      };

      if (tempFile.fileName.endsWith('.jpeg')) {
        setPhotos((prevPhotos) => updateList(prevPhotos, tempFile));
        setPDFs((prevPDFs) =>
          prevPDFs.filter((pdf) => pdf.tag.detail !== tempFile.tag.detail)
        );
      } else if (tempFile.fileName.endsWith('.pdf')) {
        setPDFs((prevPDFs) => updateList(prevPDFs, tempFile));
        setPhotos((prevPhotos) =>
          prevPhotos.filter((photo) => photo.tag.detail !== tempFile.tag.detail)
        );
      }
    } else {
      // Agrega el nuevo archivo si no existe
      if (tempFile.fileName.endsWith('.jpeg')) {
        setPhotos((prevPhotos) => [...prevPhotos, tempFile]);
      } else if (tempFile.fileName.endsWith('.pdf')) {
        setPDFs((prevPDFs) => [...prevPDFs, tempFile]);
      }
    }

    // Limpia el archivo temporal y la confirmación
    setTempFile(undefined);
    setConfirmFile(false);
  }, [confirmFile, tempFile]);

  // useEffect(() => {
  //   console.log('tempFile:', tempFile);
  //   console.log('Photos:', photos);
  //   console.log('PDFs:', PDFs);
  // }, [photos, PDFs, tempFile]);

  return (
    <MediaContext.Provider
      value={{
        takePhoto,
        deletePhoto,
        clearPhotos,
        handlePDF,
        deletePDF,
        clearPDFs,
        deleteCurrentMediaFile,
        elementExistInMedia,
        photos,
        setPhotos,
        PDFs,
        tempFile,
        setTempFile,
        media,
        confirmFile,
        setConfirmFile,
        showMediaState,
        setShowMediaState,
        fileToBeViewed,
        setFileToBeViewed,
        disablePdfUpload,
        setDisablePdfUpload,
        comprobanteMedia,
        setComprobanteMedia,
        uploadingComprobantePago,
        setUploadingComprobantePago,
        showDuplicatedInMediaToast,
        setShowDuplicatedInMediaToast,
        showFileSizeValidationToast,
        setShowFileSizeValidationToast,
        showSuccessfulComprobanteToast,
        setShowSuccessfulComprobanteToast,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

const useMedia = (): MediaContextType => {
  const context = useContext(MediaContext);
  if (context === undefined) {
    throw new Error('useMedia must be used within a MediaProvider');
  }
  return context;
};

export { MediaProvider, useMedia };
