import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { IonPage, IonContent, useIonRouter } from '@ionic/react';
import moment from 'moment';

import { GET_PROVINCIA } from '../../axios/constants';
import { getLocalidades } from '../../axios/getLocalidades';
import { getProvincia } from '../../axios/provincia';
import AddressDataForm from '../../components/AddPayment/AddressDataForm';
import AdviceAlert from '../../components/AdviceAlert';
import EditDataPopover from '../../components/EditDataPopover';
import NavigationBar from '../../components/NavigationBar';
import PersonalDataForm from '../../components/PersonalDataForm';
import { useAddSolicitud } from '../../hooks/useAddSolicitud';
import { useUserData } from '../../hooks/useUserData';
import { LocalidadRes } from '../../interfaces/Localidad';
import {
  PersonalDataReq,
  AddressDataReq,
  getEmptyPersonalData,
  getEmptyAddressData,
  PersonalDataModificationRequestToSend,
} from '../../interfaces/PersonalData';
import {
  nonNullFields,
  assignDataToObj,
} from '../../utils/Form';

type ParamsType = {
  id: string;
};

const PersonalDataPage: React.FC = () => {
  const history = useIonRouter();
  const { id: petId } = useParams<ParamsType>();
  const { data } = useUserData();
  const { addSolicitud } = useAddSolicitud();
  const [userData, setUserData] = useState<PersonalDataReq>();
  const [addressData, setAddressData] = useState<AddressDataReq>();
  const [showAddressForm, setShowAddressForm] = useState<boolean>(false);
  const [nonNullFields, setNonNullFields] = useState<nonNullFields[]>([]);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [isOkAlertOpen, setOkAlertOpen] = useState<boolean>(false);
  const [isFailAlertOpen, setFailAlertOpen] = useState<boolean>(false);
  const [addData, setAddData] = useState<number>(0);

  const nextForm = () => setShowAddressForm(true);
  const prevForm = () => setShowAddressForm(false);

  useEffect(() => {
    if (data) {
      const obj: PersonalDataReq = getEmptyPersonalData();
      const obj1: AddressDataReq = getEmptyAddressData();

      assignDataToObj(data, obj, setUserData);
      assignDataToObj(data.datosDomicilio, obj1, setAddressData);
    }
  }, [data]);

  useEffect(() => {
    const fetchProvincia = async () => {
      if (addressData?.localidadId) {
        const provincia = await getProvincia(
          GET_PROVINCIA,
          String(addressData.localidadId)
        );
        setAddressData((prevState) => ({
          ...prevState!,
          provincia,
        }));
      }
    };

    fetchProvincia();
  }, [addressData?.localidadId]);

  useEffect(() => {
    const fetchLocalidades = async () => {
      if (
        addressData?.provincia !== undefined &&
        addressData?.localidadId !== undefined
      ) {
        try {
          const localidades: LocalidadRes[] = await getLocalidades(
            addressData.provincia
          );
          const localidad = localidades.find(
            (loc) => loc.id === addressData.localidadId
          )?.nombre;

          if (localidad) {
            setAddressData((prevState) => ({
              ...prevState!,
              localidad,
            }));
          }
        } catch (error) {
          console.error('Error fetching localidades:', error);
        }
      }
    };

    fetchLocalidades();
  }, [addressData?.provincia, addressData?.localidadId]);

  const submitSolicitud = async () => {
    try {
      const personaSolicitud: PersonalDataModificationRequestToSend = {
        mascotaId: Number(petId),
        nombre: null,
        apellido: null,
        dni: null,
        telefono: null,
        fechaNac: null,
        sexo: null,
        direccion: null,
        altura: null,
        piso: null,
        dpto: null,
        provincia: null,
        localidad: null,
      };

      const keyMap: {
        [key: string]: keyof PersonalDataModificationRequestToSend;
      } = {
        nombre: 'nombre',
        apellido: 'apellido',
        dni: 'dni',
        'tel celular': 'telefono',
        'fecha nacimiento': 'fechaNac',
        genero: 'sexo',
        direccion: 'direccion',
        numero: 'altura',
        piso: 'piso',
        departamento: 'dpto',
        provincia: 'provincia',
        localidad: 'localidad',
      };

      nonNullFields.forEach((item) => {
        const mappedKey = keyMap[item.key];
        if (mappedKey) {
          if (mappedKey === 'fechaNac') {
            // Convertir la cadena de fecha a un objeto Date
            const date = moment(item.value, 'DD/MM/YYYY');
            if (date.isValid()) {
              personaSolicitud[mappedKey] = date.toDate();
            } else {
              console.error('Fecha inválida:', item.value);
            }
          } else {
            personaSolicitud[mappedKey] = item.value as string | null;
          }
        }
      });

      delete personaSolicitud.localidad;
      personaSolicitud.localidadId = Number(addressData?.localidadId);

      await addSolicitud(personaSolicitud);
      setOkAlertOpen(true);
    } catch (error) {
      console.error('Error submitting solicitud:', error);
      setFailAlertOpen(true);
    }
  };

  const goHome = () => {
    history.push('/tabs/home', 'root', 'push');
  };

  return (
    <IonPage>
      <NavigationBar goBack />
      <IonContent>
        <div className="personal-data-page__content">
          {userData && !showAddressForm ? (
            <PersonalDataForm
              actualValues={userData}
              liftValues={setUserData}
              nextForm={nextForm}
            />
          ) : (
            <AddressDataForm
              actualValues={addressData}
              liftValues={setAddressData}
              prevFormValues={userData}
              userReq={data}
              setAddData={setAddData}
              setShowPopover={setShowPopover}
              setNonNullFields={setNonNullFields}
              prevForm={prevForm}
            />
          )}
        </div>

        <div>
          <AdviceAlert
            isOpen={isOkAlertOpen}
            setOpen={setOkAlertOpen}
            header="¡ Éxito !"
            subHeader=""
            message="Sus datos personales se cargaron correctamente!"
            action={goHome}
            onDidDismiss={goHome}
          />
        </div>
        <div>
          <AdviceAlert
            isOpen={isFailAlertOpen}
            setOpen={setFailAlertOpen}
            header="¡ Ups !"
            subHeader=""
            message="Disculpe, sus datos no se pudieron cargar, intente nuevamente más tarde."
            action={() => {}}
          />
        </div>

        <EditDataPopover
          editDataPopoverState={popoverState}
          nonNullFields={nonNullFields}
          setEditDataPopoverState={setShowPopover}
          callback={submitSolicitud}
          message="Una vez aprobado, el cambio se hará efectivo en un plazo de hasta 72 horas hábiles."
        />
      </IonContent>
    </IonPage>
  );
};

export default PersonalDataPage;
