export const isOdd = (n: number) => {
  return n % 2 === 1;
};

// https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  const match = arr[0].match(/:(.*?);/);
  const mime = match === null ? undefined : match[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function capitalizeEveryWord(str?: string) {
  return str
    ? str
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : '';
}

export function capitalizeFirstLetter(str?: string) {
  return str
    ? str.replace(/(^|[.]\s+)(\w)/g, (match, p1, p2) => p1 + p2.toUpperCase())
    : '';
}

export const removeAccents = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const removeSpecialCharacters = (str: string) => {
  return str.split('_').join('');
};

export const removeSpaces = (str: string): string => {
  return str.replace(/\s+/g, '');
};

export const cleanUndefined = (str: string) => {
  return str
    .split(',')
    .map((part) => part.trim())
    .filter((part) => part !== 'undefined')
    .join(', ');
};

export const formatMediaDetailName = (detail: string) =>
  detail
    .replace(/\$.*/, '')  // Elimina el carácter $ y todo lo que sigue
    .replace(/_/g, ' ')  // Reemplaza los guiones bajos por espacios
    .replace(/([a-z])([A-Z])/g, '$1 $2')  // Reemplaza transiciones de minúscula a mayúscula por un espacio
    .split(' ')  // Divide la cadena en palabras
    .map((word, index) =>
      index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()
    )  // Capitaliza solo la primera palabra
    .join(' ');  // Une las palabras en una cadena


export function formatCreditCardNumber(e) {
  const inputVal = e.target.value;

  // Eliminar todos los caracteres no numéricos
  const formattedInput = inputVal.replace(/\D/g, '');

  // Agregar un espacio cada 4 dígitos
  let formattedCreditCardNumber = '';
  for (let i = 0; i < formattedInput.length; i++) {
    if (i > 0 && i % 4 === 0) {
      formattedCreditCardNumber += ' ';
    }
    formattedCreditCardNumber += formattedInput[i];
  }

  // setCreditCardNumber(formattedCreditCardNumber);
  return formattedCreditCardNumber.slice(0, 19);
}

export function formatCreditCardDate(e) {
  const inputVal = e.target.value;

  // Eliminar todos los caracteres no numéricos
  const formattedInput = inputVal.replace(/\D/g, '');

  let formattedCreditCardDate = '';

  // Agregar los primeros dos caracteres
  if (formattedInput.length > 2) {
    formattedCreditCardDate += formattedInput.substring(0, 2);
    formattedCreditCardDate += '/';
    formattedCreditCardDate += formattedInput.substring(2);
  } else {
    formattedCreditCardDate = formattedInput;
  }

  return formattedCreditCardDate.slice(0, 5);
}
